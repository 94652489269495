import React, {useState} from 'react'
import {Box, TextField} from '@mui/material'
import DataGridFilter, {SearchOptionsProp} from "../../../../../components/DataGridFilter";
import Header from "../../../../../components/Header";
import {connect} from "react-redux";
import {AuthToken} from "../../../../../actions/auth";
import {useAuthQueryWithQueryFunction} from "../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../interfaces/ErrorType";
import {fetchDevelopmentStages} from "../../../../../actions/developmentStage";
import AutocompleteExt from "../../../../../components/Autocomplete";
import {fetchAdminCrawlerSampleQuestions} from "../../../../../actions/crawlerSampleQuestion";
import {
    CrawlerCustomSampleQuestionFilterOptions,
    CrawlerSampleQuestion
} from "../../../../../interfaces/CrawlerSampleQuestionType";
import CrawlerSampleQuestionConfigurationDetail from "./detail";
import {DevelopmentStage} from "../../../../../interfaces/KnowledgeHub";

const CrawlerSampleQuestionConfiguration = (props: {user: AuthToken}) => {

    const { user } = props
    const [change, setChange] = useState<string>()

    const [customSearchOptions, setCustomSearchOptions] =
        useState<CrawlerCustomSampleQuestionFilterOptions>({
            filteredByDevelopmentStageId: undefined,
            filteredByWeek: NaN,
        })
    const [developmentStage, setDevelopmentStage] = useState<DevelopmentStage>()

    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    });

    const customSearchOptionsRenderer = () => (
        <>
            <AutocompleteExt
                name="filteredByDevelopmentStageId"
                multiSelection={false}
                label="Development Stage..."
                selectedValue={customSearchOptions.filteredByDevelopmentStageId}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByDevelopmentStageId: value,
                        filteredByWeek: undefined,
                    })

                    const selectedDevelopmentStage = developmentStageQuery.data?.find((ds) => ds.id === value)
                    setDevelopmentStage(selectedDevelopmentStage)
                }}
                options={availableDevelopmentStageOptions}
            />

            <TextField
                type="number"
                variant="filled"
                name="filteredByWeek"
                value={customSearchOptions.filteredByWeek || ''}
                label={`Week ${developmentStage?.id ? ` (${developmentStage.weekFrom} - ${developmentStage.maxThreshold ? developmentStage.maxThreshold : developmentStage.weekTo})` : ''}`}
                InputProps={{
                    inputProps: {
                        min: developmentStage?.weekFrom || 1,
                        max: developmentStage?.maxThreshold ? developmentStage?.maxThreshold : (developmentStage?.weekTo || Number.MAX_VALUE)
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByWeek: parseInt(event.target.value),
                    })
                }}
            />
        </>
    )

    const expandRow = (row: CrawlerSampleQuestion) => (
        <CrawlerSampleQuestionConfigurationDetail isNew={false} wrapper={row} callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAdminCrawlerSampleQuestions(searchOptions)
    }

    const columns = [
        {
            dataField: 'developmentStageId',
            text: 'Development Stage',
            sort: false,
            converter: (value: any) => {
                const developmentStage = developmentStageQuery.data?.find((ds) => ds.id === value)
                return developmentStage?.type
            },
        },
        {
            dataField: 'question',
            text: 'Question',
            sort: true,
        },
        {
            dataField: 'week',
            text: 'Week',
            sort: true,
        },
        {
            dataField: 'weekTo',
            text: 'Week To',
            sort: true,
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Crawler Sample Question Configuration' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`admin-crawler-sample-question-configuration`}
                columns={columns}
                change={change}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                customSearchOptions={customSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl={user?.user?.role === 'ADMIN' ? "/crawler/sample/question/create" : ""}
                hasCreatePermission={user?.user?.role === 'ADMIN'}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerSampleQuestionConfiguration)