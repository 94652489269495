import React, {useState} from 'react'
import {Box, TextField} from '@mui/material'
import {connect} from "react-redux";
import Header from "../../../components/Header";
import DataGridFilter, {SearchOptionsProp} from "../../../components/DataGridFilter";
import {AuthToken} from "../../../actions/auth";
import {
    CrawlerPublicDataset,
    CrawlerPublicDatasetVectorSearchFilterOptions
} from "../../../interfaces/CrawlerPublicDatasetType";
import AutocompleteExt from "../../../components/Autocomplete";
import {useAuthQueryWithQueryFunction} from "../../../extensions/UseAuthQuery";
import {ApiError} from "../../../interfaces/ErrorType";
import {
    calculateWeekRangeByMonthAndSelectedDevelopmentStage,
    fetchDevelopmentStages
} from "../../../actions/developmentStage";
import CrawlerPublicDatasetDetail from "../../admin/crawler/public/dataset/detail";
import {searchCrawlerRecommendedPublicDataset} from "../../../actions/crawlerPublicDataset";
import {fetchAllAdminCrawlerConfigurations} from "../../../actions/crawler";
import {useMutation} from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';
import TextareaAutosizeExt from "../../../components/TextareaAutosize";
import TagInputExt from "../../../components/TagInput";
import {
    embeddedAnswerGeminiModelOptions,
    embeddedAnswerOpenAIModelOptions,
    embeddedAnswerProviderOptions
} from "../../../share/EmbeddedAnswerProviderConstant";

const CrawlerPublicDatasetVectorSearch = (props: {user: AuthToken}) => {

    const { user } = props

    const [customSearchOptions, setCustomSearchOptions] =
        useState<CrawlerPublicDatasetVectorSearchFilterOptions>({
            queryVector: '',
            topk: 3,
            week: NaN,
            weekTo: NaN,
            developmentStageId: undefined,
            developmentStage: undefined,
            includeScore: true,
            acceptableVectorSearchScore: 0.8,
            crawlerIds: [],
            generateEmbeddedAnswer: true,
            embeddedAnswerProvider: embeddedAnswerProviderOptions[0].value,
            embeddedAnswerProviderModel: embeddedAnswerOpenAIModelOptions[0].value,
            embeddedAnswerProviderMaxTokens: 4000,
        })
    const [embeddedAnswer, setEmbeddedAnswer] = useState<string>()
    const [embeddedAnswerReference, setEmbeddedAnswerReference] = useState<string[]>([])

    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const crawlerConfigurationsQuery = useAuthQueryWithQueryFunction<
        any,
        ApiError,
        any
        >('crawlerConfigurations', fetchAllAdminCrawlerConfigurations, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Calculate week range by month and selected development stage
     */
    const calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateWeekRangeByMonthAndSelectedDevelopmentStage)

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    });

    if (crawlerConfigurationsQuery.isLoading) {
        return <div>Loading...</div>
    }

    const availableCrawlerOptions = crawlerConfigurationsQuery.data?.data?.content?.map((crawlerConfiguration: any) => {
        return {
            value: crawlerConfiguration.id,
            label: crawlerConfiguration.name,
        }
    });

    const customSearchOptionsRenderer = () => (
        <>
            <TextField
                variant="filled"
                name="queryVector"
                value={customSearchOptions.queryVector}
                label="Query Vector..."
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        queryVector: event.target.value,
                    })
                }}
                required={true}
            />

            <TextField
                type="number"
                variant="filled"
                name="topk"
                value={customSearchOptions.topk}
                label="Topk..."
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        topk: parseInt(event.target.value),
                    })
                }}
                required={true}
            />

            <AutocompleteExt
                name="developmentStageId"
                multiSelection={false}
                label="Development Stage..."
                selectedValue={customSearchOptions.developmentStageId}
                onSelect={(value) => {
                    if (value) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            developmentStageId: value,
                            developmentStage: developmentStageQuery.data?.find((developmentStage) => developmentStage.id === value),
                            month: NaN,
                            week: NaN,
                            weekTo: NaN,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            developmentStageId: value,
                            developmentStage: undefined,
                            month: NaN,
                            week: NaN,
                            weekTo: NaN,
                        })
                    }
                }}
                options={availableDevelopmentStageOptions}
                required={true}
            />

            {customSearchOptions.developmentStage?.supportedMonthConversion && (
                <TextField
                    type="number"
                    variant="filled"
                    name="month"
                    value={customSearchOptions.month}
                    label={`Month (${customSearchOptions.developmentStage?.validMonthFrom} - ${customSearchOptions.developmentStage?.validMonthTo})`}
                    InputProps={{
                        inputProps: {
                            min: customSearchOptions.developmentStage?.validMonthFrom,
                            max: customSearchOptions.developmentStage?.validMonthTo,
                        },
                    }}
                    onBlur={(event) => {
                        if (!customSearchOptions.month) {
                            return;
                        }

                        if (customSearchOptions.month < customSearchOptions.developmentStage!!.validMonthFrom || customSearchOptions.month > customSearchOptions.developmentStage!!.validMonthTo) {
                            return;
                        }

                        calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.mutate({
                            month: customSearchOptions.month,
                            developmentStageId: customSearchOptions.developmentStage?.id,
                        }, {
                            onSuccess: (data) => {
                                console.log(data)
                                setCustomSearchOptions({
                                    ...customSearchOptions,
                                    month: customSearchOptions.month,
                                    week: data[0],
                                    weekTo: data[1],
                                })
                            }
                        })
                    }}
                    onChange={(event) => {
                        if (!event.target.value) {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                month: NaN,
                                week: NaN,
                                weekTo: NaN,
                            })
                        } else {
                            const month = parseInt(event.target.value)

                            setCustomSearchOptions({
                                ...customSearchOptions,
                                month: month,
                            })
                        }
                    }}
                />
            )}

            <TextField
                type="number"
                variant="filled"
                name="week"
                value={customSearchOptions.week}
                label="Week..."
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        week: parseInt(event.target.value),
                    })
                }}
            />

            <TextField
                type="number"
                variant="filled"
                name="weekTo"
                value={customSearchOptions.weekTo}
                label="Week To..."
                InputProps={{
                    inputProps: {
                        min: customSearchOptions.week ? customSearchOptions.week + 1 : 1,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        weekTo: parseInt(event.target.value),
                    })
                }}
            />

            <AutocompleteExt
                name="crawlerIds"
                multiSelection={true}
                label="Crawler..."
                selectedValue={customSearchOptions.crawlerIds}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        crawlerIds: value,
                    })
                }}
                options={availableCrawlerOptions}
            />

            <TextField
                type="number"
                variant="filled"
                name="acceptableVectorSearchScore"
                value={customSearchOptions.acceptableVectorSearchScore}
                label="Acceptable Vector Search Score..."
                InputProps={{
                    inputProps: {
                        max: 1.0,
                    },
                }}
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        acceptableVectorSearchScore: parseFloat(event.target.value),
                    })
                }}
                required={customSearchOptions.includeScore}
            />

            {customSearchOptions.generateEmbeddedAnswer && (
                <>
                    <AutocompleteExt
                        name="embeddedAnswerProvider"
                        multiSelection={false}
                        disableUnselectAll={true}
                        label="Embedded Answer Provider..."
                        selectedValue={customSearchOptions.embeddedAnswerProvider}
                        onSelect={(value) => {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                embeddedAnswerProvider: value,
                                embeddedAnswerProviderModel: value === 'OPENAI' ? embeddedAnswerOpenAIModelOptions[0].value : embeddedAnswerGeminiModelOptions[0].value,
                            })
                        }}
                        options={embeddedAnswerProviderOptions}
                    />

                    <AutocompleteExt
                        name="embeddedAnswerProviderModel"
                        multiSelection={false}
                        disableUnselectAll={true}
                        label="Embedded Answer Provider Model..."
                        selectedValue={customSearchOptions.embeddedAnswerProviderModel}
                        onSelect={(value) => {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                embeddedAnswerProviderModel: value,
                            })
                        }}
                        options={customSearchOptions.embeddedAnswerProvider === 'OPENAI' ? embeddedAnswerOpenAIModelOptions : embeddedAnswerGeminiModelOptions}
                    />
                </>
            )}
        </>
    )

    const customActionInputRenderer = () => (
        <Box display="grid" gridTemplateColumns="auto" gap="20px">
            {customSearchOptions.generateEmbeddedAnswer && (
                <TextareaAutosizeExt
                    label="Embedded Answer Additional Prompt"
                    value={customSearchOptions.embeddedAnswerAdditionalPrompt}
                    name="embeddedAnswerAdditionalPrompt"
                    onChange={(v) => {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            embeddedAnswerAdditionalPrompt: v,
                        })
                    }}
                    minRows={5}
                    maxRows={5}
                />
            )}

            {embeddedAnswer && (
                <TextareaAutosizeExt
                    label="Embedded Answer"
                    value={embeddedAnswer}
                    name="embeddedAnswer"
                    minRows={5}
                    maxRows={10}
                />
            )}

            {embeddedAnswerReference?.length > 0 && (
                <TagInputExt
                    label="Embedded Answer Reference Ids"
                    values={embeddedAnswerReference.map(
                        (reference) => {
                            return {
                                id: reference,
                                text: reference,
                            }
                        }
                    )}
                    name="embeddedAnswerReference"
                />
            )}

        </Box>
    )

    const expandRow = (row: CrawlerPublicDataset) => (
        <CrawlerPublicDatasetDetail isNew={false} wrapper={row} editable={false} />
    )

    const onSearchPageUseQueryEvent = async (searchOptions: SearchOptionsProp) => {
        const searchResult = await searchCrawlerRecommendedPublicDataset(searchOptions)

        if (searchResult.embeddedAnswer) {
            setEmbeddedAnswer(searchResult.embeddedAnswer.answer)
            setEmbeddedAnswerReference(searchResult.embeddedAnswer.referenceIds)
        } else {
            setEmbeddedAnswer(undefined)
            setEmbeddedAnswerReference([])
        }

        return searchResult
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: false,
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: false,
        },
        {
            dataField: 'crawlerId',
            text: 'Crawler',
            sort: false,
            converter: (value: any) => {
                return availableCrawlerOptions.find((option: any) => option.value === value)?.label
            }
        },
        {
            dataField: 'crawlerId',
            text: 'Crawler Id',
            sort: false,
        },
        {
            dataField: 'week',
            text: 'Week',
            sort: false,
        },
        {
            dataField: 'weekTo',
            text: 'Week To',
            sort: false,
        },
        {
            dataField: 'score',
            text: 'Score',
            sort: false,
        },
    ]

    return (
        <LoadingOverlay active={calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
            <Box m='20px'>
                <Header title='Crawler Dataset (RAG)' />

                <DataGridFilter
                    keyField='id'
                    useQueryKey={`admin-crawler-recommended-public-dataset`}
                    columns={columns}
                    onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                    customSearchOptions={customSearchOptions}
                    customSearchOptionsRenderer={customSearchOptionsRenderer()}
                    customActionInputRenderer={customActionInputRenderer()}
                    resetCustomSearchOptions={(customSearchOptions) => {
                        setCustomSearchOptions(customSearchOptions)
                        setEmbeddedAnswer(undefined)
                        setEmbeddedAnswerReference([])
                    }}
                    searchFilterCols={3}
                    disabledKeyword={true}
                    disabledMatchAll={true}
                    expandRow={expandRow}
                    hasPagination={false}
                    preloadEnabled={false}
                />
            </Box>
        </LoadingOverlay>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerPublicDatasetVectorSearch)