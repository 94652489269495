import React  from 'react'
import { Box } from '@mui/material'
import DataGridFilter, {SearchOptionsProp} from "../../../../../components/DataGridFilter";
import Header from "../../../../../components/Header";
import {CrawlerTextRegexExtractor} from "../../../../../interfaces/CrawlerTextRegexExtractorType";
import CrawlerRegexExtractorDetail from "./detail";
import {fetchAdminCrawlerTextRegexExtractors} from "../../../../../actions/crawlerTextRegexExtractor";
import {connect} from "react-redux";
import {AuthToken} from "../../../../../actions/auth";

const CrawlerRegexExtractor = (props: {user: AuthToken}) => {

    const { user } = props

    const expandRow = (row: CrawlerTextRegexExtractor) => (
        <CrawlerRegexExtractorDetail isNew={false} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAdminCrawlerTextRegexExtractors(searchOptions)
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'matchesRegexPattern',
            text: 'Matches Regex Pattern',
            sort: false,
        },
        {
            dataField: 'extractValueRegexPattern',
            text: 'Extract Value Regex Pattern',
            sort: false,
        },
        {
            dataField: 'valueType',
            text: 'Value Type',
            sort: false,
        },
        {
            dataField: 'priority',
            text: 'Priority Order',
            sort: true,
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Crawler Regex Extractor' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`admin-crawler-regex-extractor`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={2}
                disabledMatchAll={true}
                createPageUrl={user?.user?.role === 'ADMIN' ? "/crawler/regex/extractor/create" : ""}
                hasCreatePermission={user?.user?.role === 'ADMIN'}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerRegexExtractor)