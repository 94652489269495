import SecureAxios from "../extensions/SecureAxios";
import store from "../redux/store";
import {UPDATE_TABLE_DATA_ITEM} from "../redux/actionType";

/**
 * An action to fetch admin crawler configuration
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const fetchAdminCrawlerConfigurations = async (searchOptions) => {
    let adminCrawlerSearchUrl = `/crawlers?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        adminCrawlerSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.filteredByDataStatus) {
        adminCrawlerSearchUrl += `&filteredByDataStatus=${searchOptions.customSearchOptions.filteredByDataStatus}`
    }

    if (searchOptions.customSearchOptions?.filteredByDevelopmentStageIds) {
        adminCrawlerSearchUrl += `&filteredByDevelopmentStageIds=${searchOptions.customSearchOptions.filteredByDevelopmentStageIds}`
    }

    const { data } = await SecureAxios.get(adminCrawlerSearchUrl)
    return data
}

export const fetchAllAdminCrawlerConfigurations = async () => {
    let adminCrawlerSearchUrl = `/crawlers?keyword=&page=1&size=100000&filteredByDataStatuses=PUBLISHED&filteredByDataStatuses=PUBLISHING&filteredByDataStatuses=UPDATING`
    const { data } = await SecureAxios.get(adminCrawlerSearchUrl)
    return data
}

/**
 * An action to fetch the latest crawler data
 * @returns {Promise<*>}
 */
export const fetchLatestCrawlerData = async (crawlerId, searchOptions) => {
    if (!searchOptions) {
        const { data } = await SecureAxios.get(`/crawler/${crawlerId}/data/latest`)
        return data.data
    } else {
        let latestCrawlerDataUrl = `/crawler/${crawlerId}/data/latest?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`

        if (searchOptions.customSearchOptions?.keywordMatchedBy) {
            latestCrawlerDataUrl += `&keywordMatchedBy=${searchOptions.customSearchOptions.keywordMatchedBy}`
        }

        if (searchOptions.customSearchOptions?.week) {
            latestCrawlerDataUrl += `&week=${searchOptions.customSearchOptions.week}`
        }

        if (searchOptions.customSearchOptions?.filteredByExcludedByNlpType) {
            latestCrawlerDataUrl += `&filteredByExcludedByNlpType=${searchOptions.customSearchOptions.filteredByExcludedByNlpType}`
        }

        if (searchOptions.customSearchOptions?.filteredByExcludedByPatterns) {
            latestCrawlerDataUrl += `&filteredByExcludedByPatterns=true`
        }

        latestCrawlerDataUrl += `&active=${searchOptions.customSearchOptions.active}&filteredBySpecialCharacters=${searchOptions.customSearchOptions.filteredBySpecialCharacters}`

        const { data } = await SecureAxios.get(latestCrawlerDataUrl)
        return data.data
    }
}

export const getCrawler = async (crawlerId) => {
    const { data } = await SecureAxios.get(`/crawlers/${crawlerId}`)
    return data.data
}

/**
 * An action to analyse crawler text nlp exclusion rule definition
 * @returns {Promise<*>}
 */
export const analyseCrawlerTextNlpExclusionRuleDefinition = async (request) => {
    const { data } = await SecureAxios.get(`/admin/crawler/${request.crawlerId}/data/chunk/${request.crawlerChunkId}/nlp/exclusion/rule/definition`)
    return data
}

/**
 * An action to analyse crawler text pattern exclusion rule definition
 * @returns {Promise<*>}
 */
export const analyseCrawlerTextPatternExclusionRuleDefinition = async (request) => {
    const { data } = await SecureAxios.get(`/admin/crawler/${request.crawlerId}/data/chunk/${request.crawlerChunkId}/patterns/exclusion/rule/definition`)
    return data
}

/**
 * An action to mark crawler text as active
 * @returns {Promise<*>}
 */
export const markCrawlerTextActive = async (request) => {
    const { data } = await SecureAxios.post(`/admin/crawler/${request.crawlerId}/data/chunk/${request.crawlerChunkId}/mark/active`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * An action to mark crawler text as inactive
 * @returns {Promise<*>}
 */
export const markCrawlerTextInactive = async (request) => {
    const { data } = await SecureAxios.post(`/admin/crawler/${request.crawlerId}/data/chunk/${request.crawlerChunkId}/mark/inactive`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}


/**
 * An action to save crawler
 */
export async function saveCrawler(crawler) {
    const requestBody = {
        ...crawler,
    }
    let crawlerUrl = `/admin/crawler`
    if (crawler.id) {
        crawlerUrl += `/${crawler.id}/update`

        const { data } = await SecureAxios.put(crawlerUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        store.dispatch({
            type: UPDATE_TABLE_DATA_ITEM,
            payload: { key: 'admin-crawler-configuration', keyField: 'id', data: data.data },
        })

        return data.data
    } else {
        const { data } = await SecureAxios.post(crawlerUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return data.data
    }
}

export async function publishCrawler(crawlerId) {
    await SecureAxios.post(`/admin/crawler/${crawlerId}/data/latest/publish`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export async function withdrawCrawler(crawlerId) {
    await SecureAxios.post(`/admin/crawler/${crawlerId}/data/latest/withdraw`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export async function deleteCrawler(crawlerId) {
    await SecureAxios.delete(`/admin/crawler/${crawlerId}/delete`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export async function deleteCrawlerData(crawlerId) {
    await SecureAxios.delete(`/admin/crawler/${crawlerId}/data`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}