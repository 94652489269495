export const embeddedAnswerProviderOptions = [
    {
        value: 'OPENAI',
        label: 'Open AI',
    },
    {
        value: 'CLAUDE',
        label: 'Claude',
    },
    {
        value: 'GEMINI',
        label: 'Gemini',
    },
]

export const embeddedAnswerOpenAIModelOptions = [
    {
        value: 'gpt-4o',
        label: 'GPT-4o',
    },
    {
        value: 'gpt-4o-mini',
        label: 'GPT-4o Mini',
    },
    {
        value: 'o1',
        label: 'O1',
    },
    {
        value: 'o3-mini',
        label: 'O3 Mini',
    },
    {
        value: 'o1-mini',
        label: 'O1 Mini',
    },
    {
        value: 'gpt-4.5-preview',
        label: 'GPT 4.5 Preview',
    },
    {
        value: 'gpt-3.5-turbo',
        label: 'GPT 3.5 Turbo',
    },
    {
        value: 'gpt-3.5-turbo-16k',
        label: 'GPT 3.5 Turbo 16k',
    },
]

export const embeddedAnswerClaudeModelOptions = [
    {
        value: 'claude-3-5-sonnet-20241022',
        label: 'Claude 3.5 Sonnet (2024-10-22)',
    },
]

export const embeddedAnswerClaudeModelVersionLookups = [
    {
        'model': 'claude-3-5-sonnet-20241022',
        'version': '2023-06-01'
    }
]

export const embeddedAnswerGeminiModelOptions = [
    {
        value: 'gemini-1.5-flash',
        label: 'Gemini 1.5 Flash',
    },
]