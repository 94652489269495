import React, { useState } from 'react'
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { tokens } from '../../theme'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import PestControlIcon from '@mui/icons-material/PestControl'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PatternIcon from '@mui/icons-material/Pattern';
import PublicIcon from '@mui/icons-material/Public';
import { connect } from 'react-redux'
import {AuthScope, AuthToken} from "../../actions/auth";

interface ItemProps {
    title: string
    to: string
    icon: React.ReactNode
    selected: string
    setSelected: (title: string) => void
}

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    )
}

const Sidebar = (props: { user: AuthToken }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState('Dashboard')
    const { user } = props

    return (
        <Box
            sx={{
                '& .pro-sidebar': {
                    minHeight: '100vh',
                },
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`,
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important',
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important',
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important',
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important',
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* Logo and Menu Items */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 10px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box alignItems="center">
                                <Box textAlign="center">
                                    <img
                                        alt="Neurofrog"
                                        width="auto"
                                        height="80px"
                                        src={`../../neurofrog.png`}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="40px"
                                >
                                    <Typography
                                        variant="h4"
                                        color={colors.grey[100]}
                                    >
                                        CRAWLER
                                    </Typography>
                                    <IconButton
                                        onClick={() =>
                                            setIsCollapsed(!isCollapsed)
                                        }
                                    >
                                        <MenuOutlinedIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </MenuItem>
                    {/* User */}
                    {!isCollapsed && (
                        <Box mb="10px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`../../assets/user.png`} //ref: https://icons8.com/icon/z-JBA_KtSkxG/test-account
                                />
                            </Box>

                            {user?.user?.username && (
                                <Box textAlign="center">
                                    <Typography
                                        variant="h5"
                                        color={colors.grey[100]}
                                        fontWeight="bold"
                                        sx={{ m: '10px 0 0 0' }}
                                    >
                                        {user.user.username.toUpperCase()}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}

                    {/* Menu Items */}
                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {user?.user?.role && user.user.role === 'ADMIN' && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '5px 0 5px 20px' }}
                                >
                                    Configuration
                                </Typography>

                                <Item
                                    title="Crawler Regex Extractor"
                                    to="/crawler/regex/extractor"
                                    icon={<PatternIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Crawler"
                                    to="/crawler"
                                    icon={<PestControlIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Crawler Question (Lib)"
                                    to="/crawler/sample/question"
                                    icon={<QuestionAnswerIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '5px 0 5px 20px' }}
                        >
                            Published
                        </Typography>

                        {user?.user?.role && user.user.role === 'ADMIN' && (
                            <Item
                                title="Crawler Dataset"
                                to="/crawler/public/dataset"
                                icon={<PublicIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}

                        <Item
                            title="Crawler Question"
                            to="/crawler/public/sample/question"
                            icon={<PublicIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        <Item
                            title="Crawler Dataset (RAG)"
                            to="/crawler/public/dataset/rag"
                            icon={<PublicIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(Sidebar)
