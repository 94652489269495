import React, {FunctionComponent, useState} from "react";
import {AnalyzedCrawlerText, CrawlerTexts, CrawlerTextsWrapper} from "../../../../../interfaces/CrawlerDataType";
import {useMutation} from "react-query";
import {
    analyseCrawlerTextNlpExclusionRuleDefinition,
    analyseCrawlerTextPatternExclusionRuleDefinition, markCrawlerTextActive, markCrawlerTextInactive
} from "../../../../../actions/crawler";
import {Box, TextField} from "@mui/material";
import TextareaAutosizeExt from "../../../../../components/TextareaAutosize";
import ButtonExt from "../../../../../components/ButtonExt";
import { v4 as uuidv4 } from 'uuid'

const CrawlerDataChunkDetail: FunctionComponent<CrawlerTextsWrapper> = ({
                                                                            crawlerId,
                                                                            crawlerData,
                                                                            crawlerText,
                                                                            callback
                                                                        }) => {


    const [analyzeResult, setAnalyzeResult] = useState<AnalyzedCrawlerText | undefined>()

    /**
     * Mutate Analyse crawler text nlp exclusion rule definition
     */
    const analyseCrawlerTextNlpExclusionRuleDefinitionMutation = useMutation<AnalyzedCrawlerText, Error, any>(
        analyseCrawlerTextNlpExclusionRuleDefinition
    )

    /**
     * Mutate Analyse crawler text pattern exclusion rule definition
     */
    const analyseCrawlerTextPatternExclusionRuleDefinitionMutation = useMutation<AnalyzedCrawlerText, Error, any>(
        analyseCrawlerTextPatternExclusionRuleDefinition
    )

    /**
     * Mutate Mark crawler text active
     */
    const markCrawlerTextActiveMutation = useMutation<undefined, Error, any>(
        markCrawlerTextActive
    )

    /**
     * Mutate Mark crawler text inactive
     */
    const markCrawlerTextInactiveMutation = useMutation<undefined, Error, any>(
        markCrawlerTextInactive
    )

    const handleAnalyseCrawlerTextNlpExclusionRuleDefinition = (crawlerId: string, crawlerChunkId: string) => {
        analyseCrawlerTextNlpExclusionRuleDefinitionMutation.mutate({ crawlerId: crawlerId, crawlerChunkId: crawlerChunkId }, {
            onSuccess(data) {
                setAnalyzeResult(data)
            }
        })
    }

    const handleAnalyseCrawlerTextPatternExclusionRuleDefinition = (crawlerId: string, crawlerChunkId: string) => {
        analyseCrawlerTextPatternExclusionRuleDefinitionMutation.mutate({ crawlerId: crawlerId, crawlerChunkId: crawlerChunkId }, {
            onSuccess(data) {
                setAnalyzeResult(data)
            }
        })
    }

    const handleMarkCrawlerTextActive = (crawlerId: string, crawlerChunkId: string) => {
        markCrawlerTextActiveMutation.mutate({ crawlerId: crawlerId, crawlerChunkId: crawlerChunkId }, {
            onSuccess() {
                if (callback) {
                    callback(uuidv4())
                }
            }
        })
    }

    const handleMarkCrawlerTextInactive = (crawlerId: string, crawlerChunkId: string) => {
        markCrawlerTextInactiveMutation.mutate({ crawlerId: crawlerId, crawlerChunkId: crawlerChunkId }, {
            onSuccess() {
                if (callback) {
                    callback(uuidv4())
                }
            }
        })
    }

    return (
        <>
            <Box
                display='grid'
                mt="20px"
                gap='30px'
                gridTemplateColumns='repeat(2, minmax(0,1fr))'
            >
                <TextField
                    variant='filled'
                    type='text'
                    label='Id'
                    value={crawlerText.id}
                    name='id'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Last Update'
                    value={crawlerText.updatedAt}
                    name='updatedAt'
                />
            </Box>

            <Box
                display='grid'
                mt="20px"
                gap='30px'
                gridTemplateColumns='repeat(1, minmax(0,1fr))'
            >
                <TextField
                    variant='filled'
                    type='text'
                    label='URL'
                    value={crawlerText.url}
                    name='url'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Title'
                    value={crawlerText.title}
                    name='title'
                />

                <TextareaAutosizeExt
                    label="Text"
                    value={crawlerText.text}
                    minRows={10}
                    maxRows={10}
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Weeks'
                    value={crawlerText.weeks ? crawlerText.weeks.join(",") : "-"}
                    name='weeks'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Range'
                    value={crawlerText.range ? "Yes" : "No"}
                    name='range'
                />

                <Box
                    display='grid'
                    gridTemplateColumns='4fr 1fr'
                    mt='20px'
                    gap='20px'
                >
                    <TextField
                        variant='filled'
                        type='text'
                        label='Excluded By NLP Type'
                        value={crawlerText.excludedByNlpType.length > 0 ? crawlerText.excludedByNlpType.join(", ") : "No"}
                        name='excludedByNlpType'
                    />

                    {crawlerText.excludedByNlpType.length > 0 && (
                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    analyseCrawlerTextNlpExclusionRuleDefinitionMutation.isLoading
                                        ? 'Analyzing'
                                        : 'Analyze'
                                }
                                onClickEvent={() => handleAnalyseCrawlerTextNlpExclusionRuleDefinition(crawlerId, crawlerText.id)}
                                disabled={
                                    analyseCrawlerTextNlpExclusionRuleDefinitionMutation.isLoading
                                }
                            />
                        </Box>
                    )}

                    <TextField
                        variant='filled'
                        type='text'
                        label='Excluded By Acceptable Min Chunk Size In Words'
                        value={crawlerText.excludedByAcceptableMinChunkSizeInWords ? "Yes" : "No"}
                        name='excludedByAcceptableMinChunkSizeInWords'
                    />
                </Box>

                <Box
                    display='grid'
                    gridTemplateColumns='4fr 1fr'
                    mt='20px'
                    gap='20px'
                >
                    <TextField
                        variant='filled'
                        type='text'
                        label='Excluded By Patterns'
                        value={crawlerText.excludedByPatterns ? "Yes" : "No"}
                        name='excludedByNlpType'
                    />

                    {crawlerText.excludedByPatterns && (
                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    analyseCrawlerTextPatternExclusionRuleDefinitionMutation.isLoading
                                        ? 'Analyzing'
                                        : 'Analyze'
                                }
                                onClickEvent={() => handleAnalyseCrawlerTextPatternExclusionRuleDefinition(crawlerId, crawlerText.id)}
                                disabled={
                                    analyseCrawlerTextPatternExclusionRuleDefinitionMutation.isLoading
                                }
                            />
                        </Box>
                    )}
                </Box>

                {analyzeResult && (
                    <TextareaAutosizeExt
                        label="Exclusion Rule Matched"
                        value={analyzeResult.exclusionRuleMatchesText.join('\n')}
                        minRows={10}
                        maxRows={10}
                    />
                )}

                {!crawlerData?.dataStatus && !crawlerText.active && (
                    <Box
                        display='flex'
                        justifyContent='end'
                        mt='20px'
                        gap='20px'
                    >
                        <ButtonExt
                            type='button'
                            value={
                                markCrawlerTextActiveMutation.isLoading
                                    ? 'Marking'
                                    : 'Mark Active'
                            }
                            onClickEvent={() => handleMarkCrawlerTextActive(crawlerId, crawlerText.id)}
                            disabled={
                                markCrawlerTextActiveMutation.isLoading
                            }
                        />
                    </Box>
                )}

                {!crawlerData?.dataStatus && crawlerText.active && (
                    <Box
                        display='flex'
                        justifyContent='end'
                        mt='20px'
                        gap='20px'
                    >
                        <ButtonExt
                            type='button'
                            value={
                                markCrawlerTextInactiveMutation.isLoading
                                    ? 'Marking'
                                    : 'Mark Inactive'
                            }
                            onClickEvent={() => handleMarkCrawlerTextInactive(crawlerId, crawlerText.id)}
                            disabled={
                                markCrawlerTextInactiveMutation.isLoading
                            }
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default CrawlerDataChunkDetail