import SecureAxios from "../extensions/SecureAxios";

/**
 * An action to fetch development stages
 * @returns {Promise<*>}
 */
export const fetchDevelopmentStages = async () => {
    let developmentStageSearchUrl = `/knowledge/hub/development/stage`

    const { data } = await SecureAxios.get(developmentStageSearchUrl)
    return data.content
}

export const resetDevelopmentStage = async (request) => {
    await SecureAxios.post(`/admin/knowledge/hub/development/stage/clear/cache`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * Calculate week range by month and selected development stage
 *
 * @param param
 * @returns {*}
 */
export const calculateWeekRangeByMonthAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/knowledge/hub/development/stage/convert/month/to/week/range?month=${param.month}&developmentStageId=${param.developmentStageId}`)
    return data
}