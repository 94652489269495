import React, {useState} from 'react'
import {Box, IconButton, TextField} from '@mui/material'
import {connect} from "react-redux";
import {ApiError} from "../../../../interfaces/ErrorType";
import {useAuthQueryWithQueryFunction} from "../../../../extensions/UseAuthQuery";
import {
    CrawlerSampleQuestionSearchFilterOptions,
    CrawlerSampleQuestionSearchResult
} from "../../../../interfaces/CrawlerSampleQuestionType";
import {fetchDevelopmentStages} from "../../../../actions/developmentStage";
import {AuthToken} from "../../../../actions/auth";
import AutocompleteExt from "../../../../components/Autocomplete";
import {fetchCrawlerSampleQuestions} from "../../../../actions/crawlerSampleQuestion";
import Header from "../../../../components/Header";
import {Formik} from "formik";
import SearchIcon from "@mui/icons-material/Search";
import ButtonExt from "../../../../components/ButtonExt";
import {useMutation} from "react-query";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {DevelopmentStage} from "../../../../interfaces/KnowledgeHub";

const CrawlerSampleQuestionSearch = (props: {user: AuthToken}) => {

    const { user } = props

    const [searchFilters, setSearchFilters] =
        useState<CrawlerSampleQuestionSearchFilterOptions>({
            developmentStageId: '',
            week: NaN,
            randomTopK: 3,
        })
    const [developmentStage, setDevelopmentStage] = useState<DevelopmentStage>()
    const [searchResults, setSearchResults] = useState<CrawlerSampleQuestionSearchResult>()

    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Mutate the crawler sample question search
     */
    const crawlerSampleQuestionSearchMutation = useMutation<CrawlerSampleQuestionSearchResult, ApiError, CrawlerSampleQuestionSearchFilterOptions>(
        fetchCrawlerSampleQuestions,
    )

    if (developmentStageQuery.isLoading) {
        return <div>Loading...</div>
    }

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    });

    const onSearch = async (values: CrawlerSampleQuestionSearchFilterOptions) => {
        setSearchFilters(values)
        await crawlerSampleQuestionSearchMutation.mutate(values, {
            onSuccess: (data) => {
                setSearchResults(data)
            },
        })
    }

    return (
        <Box m='20px'>
            <Header title='Crawler Sample Question' />

            <Formik
                onSubmit={onSearch}
                initialValues={searchFilters}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="10px">
                            <AutocompleteExt
                                name="developmentStageId"
                                multiSelection={false}
                                label="Development Stage..."
                                selectedValue={searchFilters.developmentStageId}
                                onSelect={(value) => {
                                    if (value) {
                                        setSearchFilters({
                                            ...searchFilters,
                                            developmentStageId: value,
                                            week: undefined,
                                        })

                                        values.developmentStageId = value
                                        values.week = undefined

                                        setDevelopmentStage(developmentStageQuery.data?.find((developmentStage) => developmentStage.id === value))
                                    } else {
                                        setSearchFilters({
                                            ...searchFilters,
                                            developmentStageId: undefined,
                                            week: undefined,
                                        })

                                        values.developmentStageId = undefined
                                        values.week = undefined

                                        setDevelopmentStage(undefined)
                                    }
                                }}
                                options={availableDevelopmentStageOptions}
                                required={true}
                            />

                            <TextField
                                type="number"
                                variant="filled"
                                name="week"
                                value={searchFilters.week || ''}
                                label={`Week ${developmentStage?.id ? ` (${developmentStage.weekFrom} - ${developmentStage.maxThreshold ? developmentStage.maxThreshold : developmentStage.weekTo})` : ''}`}
                                InputProps={{
                                    inputProps: {
                                        min: developmentStage?.weekFrom || 1,
                                        max: developmentStage?.maxThreshold ? developmentStage?.maxThreshold : (developmentStage?.weekTo || Number.MAX_VALUE)
                                    },
                                }}
                                onChange={(event) => {
                                    setSearchFilters({
                                        ...searchFilters,
                                        week: parseInt(event.target.value),
                                    })

                                    values.week = parseInt(event.target.value)
                                }}
                                required={true}
                            />

                            <TextField
                                type="number"
                                variant="filled"
                                name="randomTopK"
                                value={searchFilters.randomTopK}
                                label="Random Top K..."
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                    },
                                }}
                                onChange={(event) => {
                                    setSearchFilters({
                                        ...searchFilters,
                                        randomTopK: parseInt(event.target.value),
                                    })

                                    values.randomTopK = parseInt(event.target.value)
                                }}
                                required={true}
                            />
                        </Box>

                        <Box textAlign="right">
                            <ButtonExt
                                type="submit"
                                style={{
                                    width: `auto`,
                                    height: `auto`,
                                    margin: `5px`,
                                }}
                                icon={<SearchIcon />}
                                value={
                                    crawlerSampleQuestionSearchMutation.isLoading
                                        ? 'Searching...'
                                        : 'Search'
                                }
                            />
                        </Box>

                        {searchResults && searchResults.data.length > 0 && (
                            <Box>
                                <Header title="Questions" />

                                {searchResults.data.map((question, index) => (
                                    <Box display="grid" gridTemplateColumns="1fr 50px">
                                        <TextField
                                            key={`question-${index}`}
                                            value={question}
                                            multiline
                                            fullWidth
                                            variant="filled"
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true, // removes the underline in "filled" variant
                                                sx: {
                                                    '& .MuiFilledInput-root': {
                                                        border: 'none', // removes border for the filled input
                                                    },
                                                },
                                            }}
                                        />

                                        <CopyToClipboard text={question}>
                                            <IconButton color="secondary">
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </CopyToClipboard>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerSampleQuestionSearch)