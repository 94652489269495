import React, { FunctionComponent, useState } from 'react'
import {Box, IconButton, TextField} from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import {
    CrawlerTextRegexExtractor, CrawlerTextRegexExtractorRequest,
    CrawlerTextRegexExtractorWrapper
} from "../../../../../../interfaces/CrawlerTextRegexExtractorType";
import {ApiError} from "../../../../../../interfaces/ErrorType";
import {saveCrawlerTextRegexExtractor} from "../../../../../../actions/crawlerTextRegexExtractor";
import ButtonExt from "../../../../../../components/ButtonExt";
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Header from "../../../../../../components/Header";
import AutocompleteExt from "../../../../../../components/Autocomplete";
import {
    converterTypeOptions,
    textRegexExtractorValueTypeOptions
} from "../../../../../../share/CrawlerRegexExtractorConstants";
import {connect} from "react-redux";

const crawlerTextRegexExtractorSchema = yup.object().shape({
    name: yup.string().required('required'),
    matchesRegexPattern: yup.string().required('required'),
    extractValueRegexPattern: yup.string().required('required'),
    valueType: yup.string().required("required"),
    converterType: yup.string().optional(),
    priority: yup.number().required("required").min(1),
})

const CrawlerRegexExtractorDetail: FunctionComponent<CrawlerTextRegexExtractorWrapper> = ({
                                                                                              user,
                                                                                              isNew,
                                                                                              wrapper,
                                                                                          }) => {
    const navigate = useNavigate()
    const [crawlerTextRegexExtractor, setCrawlerTextRegexExtractor] = useState<CrawlerTextRegexExtractor | undefined>(wrapper)
    const [crawlerTextRegexExtractorRequest, setCrawlerTextRegexExtractorRequest] = useState<CrawlerTextRegexExtractorRequest>(crawlerTextRegexExtractor?.id ? {
        id: crawlerTextRegexExtractor.id,
        name: crawlerTextRegexExtractor.name,
        matchesRegexPattern: crawlerTextRegexExtractor.matchesRegexPattern,
        extractValueRegexPattern: crawlerTextRegexExtractor.extractValueRegexPattern,
        valueType: crawlerTextRegexExtractor.valueType,
        converterType: crawlerTextRegexExtractor.converterType,
        priority: crawlerTextRegexExtractor.priority,
    } : {
        id: undefined,
        name: '',
        matchesRegexPattern: '',
        extractValueRegexPattern: '',
        valueType: '',
        converterType: undefined,
        priority: 1,
    })

    /**
     * Invoke an action to create/ update crawler text regex extractor
     * @param {*} e - event
     */
    const onSave = (values: CrawlerTextRegexExtractorRequest) => {
        crawlerTextRegexExtractorCreateOrUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                setCrawlerTextRegexExtractor(data)
                setCrawlerTextRegexExtractorRequest(data)
                values = data
            },
        })
    }

    /**
     * Mutate crawler text regex extractor create/ update
     */
    const crawlerTextRegexExtractorCreateOrUpdateMutation = useMutation<CrawlerTextRegexExtractor, ApiError, CrawlerTextRegexExtractorRequest>(
        saveCrawlerTextRegexExtractor,
    )

    if (crawlerTextRegexExtractorCreateOrUpdateMutation.isSuccess && isNew) {
        navigate(`/crawler/regex/extractor`)
    }


    /**
     * Page containing crawler text regex extractor detail page
     */
    return (
        <Box m='20px'>
            <Formik
                onSubmit={onSave}
                initialValues={crawlerTextRegexExtractorRequest}
                validationSchema={crawlerTextRegexExtractorSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            {isNew && (
                                <>
                                    <Box
                                        display="flex"
                                        justifyContent="start"
                                        mt="20px"
                                        style={{ padding: `10px` }}
                                    >
                                        <IconButton
                                            color="secondary"
                                            onClick={() =>
                                                navigate(`/crawler/regex/extractor`)
                                            }
                                        >
                                            <ArrowBackIcon /> Back
                                        </IconButton>
                                    </Box>

                                    <Header title="Create New Crawler Regex Extractor" />
                                </>
                            )}

                            {!isNew && (
                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Id'
                                    value={values.id}
                                    name='id'
                                />
                            )}

                            <TextField
                                variant='filled'
                                type='text'
                                label='Name'
                                onChange={handleChange}
                                value={values.name}
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                name='name'
                                disabled={user?.user?.role !== 'ADMIN'}
                            />

                            <TextField
                                variant='filled'
                                type='text'
                                label='Matches Regex Pattern'
                                onChange={handleChange}
                                value={values.matchesRegexPattern}
                                error={!!touched.matchesRegexPattern && !!errors.matchesRegexPattern}
                                helperText={touched.matchesRegexPattern && errors.matchesRegexPattern}
                                name='matchesRegexPattern'
                                disabled={user?.user?.role !== 'ADMIN'}
                            />

                            <TextField
                                variant='filled'
                                type='text'
                                label='Extract Value Regex Pattern'
                                onChange={handleChange}
                                value={values.extractValueRegexPattern}
                                error={!!touched.extractValueRegexPattern && !!errors.extractValueRegexPattern}
                                helperText={touched.extractValueRegexPattern && errors.extractValueRegexPattern}
                                name='extractValueRegexPattern'
                                disabled={user?.user?.role !== 'ADMIN'}
                            />

                            <TextField
                                variant='filled'
                                type='text'
                                label='Priority Order'
                                onChange={handleChange}
                                value={values.priority}
                                error={!!touched.priority && !!errors.priority}
                                helperText={touched.priority && errors.priority}
                                name='priority'
                                disabled={user?.user?.role !== 'ADMIN'}
                            />

                            <AutocompleteExt
                                name="valueType"
                                multiSelection={false}
                                label="Value Type"
                                selectedValue={values.valueType}
                                options={textRegexExtractorValueTypeOptions}
                                onSelect={(v) => {
                                    setCrawlerTextRegexExtractorRequest({
                                        ...crawlerTextRegexExtractorRequest,
                                        valueType: v
                                    })

                                    values.valueType = v
                                }}
                                error={!!touched.valueType && !!errors.valueType}
                                helperText={touched.valueType && errors.valueType}
                                disableUnselectAll={true}
                                editable={user?.user?.role === 'ADMIN'}
                            />

                            <AutocompleteExt
                                name="converterType"
                                multiSelection={false}
                                label="Converter Type"
                                selectedValue={values.converterType}
                                options={converterTypeOptions}
                                onSelect={(v) => {
                                    setCrawlerTextRegexExtractorRequest({
                                        ...crawlerTextRegexExtractorRequest,
                                        converterType: v
                                    })

                                    values.converterType = v
                                }}
                                error={!!touched.converterType && !!errors.converterType}
                                helperText={touched.converterType && errors.converterType}
                                disableUnselectAll={false}
                                editable={user?.user?.role === 'ADMIN'}
                            />
                        </Box>

                        <Box
                            display='grid'
                            mt="20px"
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            {crawlerTextRegexExtractor?.createdBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created By'
                                        value={crawlerTextRegexExtractor.createdBy}
                                        name='createdBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created At'
                                        value={crawlerTextRegexExtractor.createdAt}
                                        name='createdAt'
                                    />
                                </>
                            )}

                            {crawlerTextRegexExtractor?.updatedBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated By'
                                        value={crawlerTextRegexExtractor.updatedBy}
                                        name='createdBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated At'
                                        value={crawlerTextRegexExtractor.updatedAt}
                                        name='createdAt'
                                    />
                                </>
                            )}
                        </Box>


                        {user?.user?.role && user.user.role === 'ADMIN' && (
                            <Box
                                display='flex'
                                justifyContent='end'
                                mt='20px'
                                gap='20px'
                            >
                                <ButtonExt
                                    type='submit'
                                    value={
                                        crawlerTextRegexExtractorCreateOrUpdateMutation.isLoading
                                            ? 'Saving'
                                            : 'Save'
                                    }
                                    disabled={
                                        crawlerTextRegexExtractorCreateOrUpdateMutation.isLoading
                                    }
                                />
                            </Box>
                        )}
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerRegexExtractorDetail)