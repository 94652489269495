import React, {useEffect, useState} from 'react'
import {Box, InputLabel, TextField} from '@mui/material'
import {
    CrawlerData,
    CrawlerDataCustomFilterOptions,
    CrawlerTexts
} from "../../../../../interfaces/CrawlerDataType";
import {useAuthQueryWithQueryFunction} from "../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../interfaces/ErrorType";
import {
    fetchLatestCrawlerData
} from "../../../../../actions/crawler";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Header from "../../../../../components/Header";
import DataGridFilter, {SearchOptionsProp} from "../../../../../components/DataGridFilter";
import SelectExt from "../../../../../components/Select";
import {
    filteredChunksToExcludeUndesirableTextBasedOnNlpOptions,
    keywordMatchedByOptions
} from "../../../../../share/CrawlerConstants";
import CheckboxExt from "../../../../../components/Checkbox";
import CrawlerDataChunkDetail from "../chunk";

const CrawlerDataDetail = (props: { crawlerId: string, callback?: (value: boolean) => void, refreshCrawlerData: boolean }) => {
    const {crawlerId, callback, refreshCrawlerData} = props
    const [change, setChange] = useState<string>()
    const [crawlerData, setCrawlerData] = useState<CrawlerData | undefined>()

    const [customSearchOptions, setCustomSearchOptions] =
        useState<CrawlerDataCustomFilterOptions>({
            keywordMatchedBy: undefined,
            week: undefined,
            filteredByExcludedByPatterns: undefined,
            filteredByExcludedByNlpType: undefined,
            filteredBySpecialCharacters: false,
            active: true,
        })

    /**
     * Fetch latest crawler data query
     */
    const latestCrawlerDataQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        CrawlerData>(['crawler-data', crawlerId], () => fetchLatestCrawlerData(crawlerId), {
        onSuccess(data) {
            setCrawlerData(data)
            if (callback) {
                callback(data?.crawlerTexts?.content.length > 0)
            }
        },
        refetchOnWindowFocus: false,
        enabled: true,
    })

    useEffect(() => {
        if (refreshCrawlerData) {
            latestCrawlerDataQuery.refetch()
        }
    }, [refreshCrawlerData])

    if (latestCrawlerDataQuery.status === 'loading') {
        return <InputLabel>Loading...</InputLabel>
    }

    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: false,
        },
        {
            dataField: 'text',
            text: 'Text',
            sort: false,
            converter: (text: string) => {
                if (text.length < 80) {
                    return text
                }
                return text.substring(0, 80) + '...'
            }
        },
        {
            dataField: "weeks",
            text: 'Weeks',
            sort: false,
        },
        {
            dataField: "range",
            text: 'Range',
            sort: false,
        }
    ]

    const customSearchOptionsRenderer = () => (
        <>
            <SelectExt
                name="keywordMatchedBy"
                multiSelection={false}
                isClearable={true}
                label="Matched By"
                selectedValue={customSearchOptions.keywordMatchedBy}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        keywordMatchedBy: value,
                    })
                }}
                options={keywordMatchedByOptions}
            />
            <TextField
                variant="filled"
                type="number"
                label="Week"
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        week: event.target.value ? parseInt(event.target.value) : undefined
                    })
                }}
                value={customSearchOptions.week}
                name="week"
            />
            <SelectExt
                name="filteredByExcludedByNlpType"
                multiSelection={false}
                isClearable={true}
                label="Filtered By Excluded By NLP Type"
                selectedValue={customSearchOptions.filteredByExcludedByNlpType}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByExcludedByNlpType: value,
                    })
                }}
                options={filteredChunksToExcludeUndesirableTextBasedOnNlpOptions}
            />
            <CheckboxExt
                name="filteredByExcludedByPatterns"
                value={customSearchOptions.filteredByExcludedByPatterns}
                label="Filtered By Excluded By Patterns"
                onChange={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByExcludedByPatterns: v
                    })
                }}
            />
            <CheckboxExt
                name="filteredBySpecialCharacters"
                value={customSearchOptions.filteredBySpecialCharacters}
                label="Filtered By Special Characters"
                onChange={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredBySpecialCharacters: v
                    })
                }}
            />
            <CheckboxExt
                name="active"
                value={customSearchOptions.active}
                label="Active"
                onChange={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        active: v
                    })
                }}
            />
        </>
    )

    const expandRow = (crawlerTexts: CrawlerTexts) => (
        <CrawlerDataChunkDetail crawlerId={crawlerId} crawlerData={crawlerData} crawlerText={crawlerTexts} callback={setChange} />
    )

    const onSearchPageUseQueryEvent = async (searchOptions: SearchOptionsProp) => {
        const data = await fetchLatestCrawlerData(crawlerId, searchOptions)
        return data.crawlerTexts;
    }

    /**
     * Page containing crawler data detail page
     */
    return (
        <Box m='20px'>
            <Box style={{marginBottom: `2em`}}>
                {latestCrawlerDataQuery.isError && (
                    <ErrorMessage error={latestCrawlerDataQuery.error}/>
                )}
            </Box>

            {crawlerData?.id && (
                <>
                    <Box
                        display='grid'
                        mt="20px"
                        gap='30px'
                        gridTemplateColumns='repeat(2, minmax(0,1fr))'
                    >
                        <TextField
                            variant='filled'
                            type='text'
                            label='Id'
                            value={crawlerData.id}
                            name='id'
                        />

                        <TextField
                            variant='filled'
                            type='text'
                            label='Last Update'
                            value={crawlerData.lastRun}
                            name='lastRun'
                        />
                    </Box>

                    <Box mt="30px">

                        <Header title="Chunks" titleVariant="h4" />

                        <DataGridFilter
                            keyField='id'
                            useQueryKey={`admin-crawler-data-chunks`}
                            change={change}
                            columns={columns}
                            onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                            searchFilterCols={2}
                            disabledMatchAll={true}
                            customSearchOptions={customSearchOptions}
                            customSearchOptionsRenderer={customSearchOptionsRenderer()}
                            expandRow={expandRow}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
}

export default CrawlerDataDetail