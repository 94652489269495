import SecureAxios from "../extensions/SecureAxios";

/**
 * An action to calculate element distance
 */
export async function calculateElementDistance(webCrawlerElementDistance) {
    const requestBody = {
        ...webCrawlerElementDistance,
    }
    const {data} = await SecureAxios.post(`/admin/web/crawler/element/distance/calculator`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data

}