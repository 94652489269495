import SecureAxios from "../extensions/SecureAxios";
import store from "../redux/store";
import {UPDATE_TABLE_DATA_ITEM} from "../redux/actionType";

export const fetchAdminCrawlerSampleQuestions = async (searchOptions) => {
    let adminCrawlerSampleQuestionsSearchUrl = `/admin/crawler/sample/question?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        adminCrawlerSampleQuestionsSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.filteredByDevelopmentStageId) {
        adminCrawlerSampleQuestionsSearchUrl += `&filteredByDevelopmentStageId=${searchOptions.customSearchOptions.filteredByDevelopmentStageId}`
    }

    if (searchOptions.customSearchOptions?.filteredByWeek) {
        adminCrawlerSampleQuestionsSearchUrl += `&filteredByWeek=${searchOptions.customSearchOptions.filteredByWeek}`
    }

    const { data } = await SecureAxios.get(adminCrawlerSampleQuestionsSearchUrl)
    return data
}

export const fetchCrawlerSampleQuestions = async (searchFilters) => {
    console.log(searchFilters)
    let crawlerSampleQuestionsSearchUrl = `/crawler/sample/question?developmentStageId=${searchFilters.developmentStageId}&week=${searchFilters.week}&randomTopK=${searchFilters.randomTopK}`
    const { data } = await SecureAxios.get(crawlerSampleQuestionsSearchUrl)
    return data
}

export async function saveCrawlerSampleQuestion(crawlerSampleQuestion) {
    const requestBody = {
        ...crawlerSampleQuestion,
    }
    let crawlerSampleQuestionUrl = `/admin/crawler/sample/question`
    if (crawlerSampleQuestion.id) {
        crawlerSampleQuestionUrl += `/${crawlerSampleQuestion.id}/update`

        const { data } = await SecureAxios.put(crawlerSampleQuestionUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        store.dispatch({
            type: UPDATE_TABLE_DATA_ITEM,
            payload: { key: 'admin-crawler-sample-question-configuration', keyField: 'id', data: data.data },
        })

        return data.data
    } else {
        const { data } = await SecureAxios.post(crawlerSampleQuestionUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return data.data
    }
}

export async function deleteCrawlerSampleQuestionById(id) {
    await SecureAxios.delete(`/admin/crawler/sample/question/${id}/delete`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}