import React, { FunctionComponent, useState } from 'react'
import {Box, TextField} from '@mui/material'
import { useMutation } from 'react-query'
import {ApiError} from "../../../../../../interfaces/ErrorType";
import ButtonExt from "../../../../../../components/ButtonExt";
import {connect} from "react-redux";
import {CrawlerPublicDataset, CrawlerPublicDatasetWrapper} from "../../../../../../interfaces/CrawlerPublicDatasetType";
import {deleteCrawlerPublicDatasetById} from "../../../../../../actions/crawlerPublicDataset";
import {v4 as uuidv4} from "uuid";
import TextareaAutosizeExt from "../../../../../../components/TextareaAutosize";
import TagInputExt from "../../../../../../components/TagInput";

const CrawlerPublicDatasetDetail: FunctionComponent<CrawlerPublicDatasetWrapper> = ({
                                                                                        user,
                                                                                        isNew,
                                                                                        wrapper,
                                                                                        callback,
                                                                                        editable
}) => {

    const [crawlerPublicDataset] = useState<CrawlerPublicDataset>(wrapper!!)

    /**
     * Invoke an action to create/ update crawler text regex extractor
     * @param {*} e - event
     */
    const onDelete = () => {
        crawlerPublicDatasetDeleteMutation.mutate(crawlerPublicDataset, {
            onSuccess: () => {
                if (callback) {
                    callback(uuidv4())
                }
            },
        })
    }

    /**
     * Mutate function to delete crawler public dataset
     */
    const crawlerPublicDatasetDeleteMutation = useMutation<any, ApiError, any>(
        deleteCrawlerPublicDatasetById,
    )


    /**
     * Page containing crawler text regex extractor detail page
     */
    return (
        <Box m='20px'>
            <Box
                display='grid'
                gap='30px'
                gridTemplateColumns='repeat(1, minmax(0,1fr))'
            >
                <TextField
                    variant='filled'
                    type='text'
                    label='Id'
                    value={crawlerPublicDataset.id}
                    name='id'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Title'
                    value={crawlerPublicDataset.title}
                    name='title'
                />

                <TextareaAutosizeExt
                    label='Text'
                    value={crawlerPublicDataset.text}
                    name='text'
                    minRows={10}
                    maxRows={10}
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='URL'
                    value={crawlerPublicDataset.url}
                    name='url'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Crawler Id'
                    value={crawlerPublicDataset.crawlerId}
                    name='crawlerId'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Crawler Data Id'
                    value={crawlerPublicDataset.crawlerDataId}
                    name='crawlerDataId'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Crawler Data Text Id'
                    value={crawlerPublicDataset.crawlerDataTextId}
                    name='crawlerDataTextId'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Week'
                    value={crawlerPublicDataset.week ? crawlerPublicDataset.week : '-' }
                    name='week'
                />

                <TextField
                    variant='filled'
                    type='text'
                    label='Week To'
                    value={crawlerPublicDataset.weekTo ? crawlerPublicDataset.weekTo : '-' }
                    name='weekTo'
                />

                {crawlerPublicDataset.knowledgeTypes && (
                    <TagInputExt
                        label='Knowledge Types'
                        values={crawlerPublicDataset.knowledgeTypes.map((knowledgeType) => {
                            return {
                                id: knowledgeType.id,
                                text: knowledgeType.structure,
                            }
                        })}
                        name='knowledgeTypes'
                    />
                )}
            </Box>

            {editable && user?.user?.role && user.user.role === 'ADMIN' && (
                <Box
                    display='flex'
                    justifyContent='end'
                    mt='20px'
                    gap='20px'
                >
                    <ButtonExt
                        type='button'
                        value={
                            crawlerPublicDatasetDeleteMutation.isLoading
                                ? 'Deleting'
                                : 'Delete'
                        }
                        onClickEvent={onDelete}
                        disabled={
                            crawlerPublicDatasetDeleteMutation.isLoading
                        }
                    />
                </Box>
            )}
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerPublicDatasetDetail)