import React, {FunctionComponent, useEffect, useState} from 'react'
import {Box, IconButton, TextField} from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import {ApiError} from "../../../../../../interfaces/ErrorType";
import ButtonExt from "../../../../../../components/ButtonExt";
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Header from "../../../../../../components/Header";
import AutocompleteExt from "../../../../../../components/Autocomplete";
import {connect} from "react-redux";
import {
    CrawlerSampleQuestion, CrawlerSampleQuestionRequest,
    CrawlerSampleQuestionWrapper
} from "../../../../../../interfaces/CrawlerSampleQuestionType";
import {
    deleteCrawlerSampleQuestionById,
    saveCrawlerSampleQuestion
} from "../../../../../../actions/crawlerSampleQuestion";
import {useAuthQueryWithQueryFunction} from "../../../../../../extensions/UseAuthQuery";
import {fetchDevelopmentStages} from "../../../../../../actions/developmentStage";
import {DevelopmentStage} from "../../../../../../interfaces/KnowledgeHub";
import TextareaAutosizeExt from "../../../../../../components/TextareaAutosize";
import { v4 as uuidv4 } from 'uuid'
import ErrorMessage from "../../../../../../components/ErrorMessage";

const crawlerSampleQuestionSchema = yup.object().shape({
    developmentStageId: yup.string().required('required'),
    question: yup.string().required('required'),
    week: yup.number().optional(),
    weekTo: yup.number().optional(),
})

const CrawlerSampleQuestionConfigurationDetail: FunctionComponent<CrawlerSampleQuestionWrapper> = ({
                                                                                                       user,
                                                                                                       isNew,
                                                                                                       wrapper,
                                                                                                       callback,
                                                                                          }) => {
    const navigate = useNavigate()
    const [crawlerSampleQuestion, setCrawlerSampleQuestion] = useState<CrawlerSampleQuestion | undefined>(wrapper)
    const [crawlerSampleQuestionRequest, setCrawlerSampleQuestionRequest] = useState<CrawlerSampleQuestionRequest>(crawlerSampleQuestion?.id ? {
        id: crawlerSampleQuestion.id,
        developmentStageId: crawlerSampleQuestion.developmentStageId!!,
        question: crawlerSampleQuestion.question,
        week: crawlerSampleQuestion.week,
        weekTo: crawlerSampleQuestion.weekTo,
    } : {
        id: undefined,
        developmentStageId: '',
        question: '',
        week: undefined,
        weekTo: undefined,
    })
    const [developmentStages, setDevelopmentStages] = useState<DevelopmentStage[] | undefined>([])
    const [developmentStage, setDevelopmentStage] = useState<DevelopmentStage>()

    const [weekError, setWeekError] = useState<string | undefined>()
    const [weekToError, setWeekToError] = useState<string | undefined>()

    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        DevelopmentStage[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    useEffect(() => {
        if (developmentStageQuery.data) {
            setDevelopmentStages(developmentStageQuery.data)
        }
    }, [developmentStageQuery.data])

    /**
     * Invoke an action to create/ update crawler sample question
     * @param {*} e - event
     */
    const onSave = (values: CrawlerSampleQuestionRequest) => {
        crawlerSampleQuestionCreateOrUpdateMutation.mutate(values, {
            onSuccess: (data) => {
                setCrawlerSampleQuestion(data)
                setCrawlerSampleQuestionRequest(data)
                values = data
            },
        })
    }

    /**
     * Invoke an action to delete crawler sample question
     */
    const onDelete = () => {
        deleteCrawlerSampleQuestionByIdMutation.mutate(crawlerSampleQuestion?.id, {
            onSuccess: () => {
                if (callback) {
                    callback(uuidv4())
                }
            },
        })
    }

    /**
     * Mutate function to create/ update crawler sample question
     */
    const crawlerSampleQuestionCreateOrUpdateMutation = useMutation<CrawlerSampleQuestion, ApiError, CrawlerSampleQuestionRequest>(
        saveCrawlerSampleQuestion,
    )

    /**
     * Mutate function to delete crawler sample question
     */
    const deleteCrawlerSampleQuestionByIdMutation = useMutation<any, ApiError, any>(
        deleteCrawlerSampleQuestionById,
    )

    if (crawlerSampleQuestionCreateOrUpdateMutation.isSuccess && isNew) {
        navigate(`/crawler/sample/question`)
    }


    /**
     * Page containing crawler sample question configuration detail
     */
    return (
        <Box m='20px'>
            <Box style={{ marginBottom: `2em` }}>
                {crawlerSampleQuestionCreateOrUpdateMutation.isError && (
                    <ErrorMessage error={crawlerSampleQuestionCreateOrUpdateMutation.error} />
                )}

                {deleteCrawlerSampleQuestionByIdMutation.isError && (
                    <ErrorMessage error={deleteCrawlerSampleQuestionByIdMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={crawlerSampleQuestionRequest}
                validationSchema={crawlerSampleQuestionSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            {isNew && (
                                <>
                                    <Box
                                        display="flex"
                                        justifyContent="start"
                                        mt="20px"
                                        style={{ padding: `10px` }}
                                    >
                                        <IconButton
                                            color="secondary"
                                            onClick={() =>
                                                navigate(`/crawler/sample/question`)
                                            }
                                        >
                                            <ArrowBackIcon /> Back
                                        </IconButton>
                                    </Box>

                                    <Header title="Create New Crawler Sample Question Configuration" />
                                </>
                            )}

                            {!isNew && (
                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Id'
                                    value={values.id}
                                    name='id'
                                />
                            )}

                            <AutocompleteExt
                                name="developmentStageId"
                                multiSelection={false}
                                label="Development Stage"
                                selectedValue={values.developmentStageId}
                                options={developmentStages?.map((each) => ({value: each.id, label: each.type,}))}
                                onSelect={(v) => {
                                    setDevelopmentStage(developmentStages?.find((each) => each.id === v))

                                    setCrawlerSampleQuestionRequest({
                                        ...crawlerSampleQuestionRequest,
                                        developmentStageId: v,
                                        week: undefined,
                                        weekTo: undefined,
                                    })

                                    values.developmentStageId = v
                                    values.week = undefined
                                    values.weekTo = undefined

                                    setWeekError(undefined)
                                    setWeekToError(undefined)
                                }}
                                error={!!touched.developmentStageId && !!errors.developmentStageId}
                                helperText={touched.developmentStageId && errors.developmentStageId}
                                disableUnselectAll={true}
                            />

                            <TextField
                                variant='filled'
                                type='text'
                                label={`${developmentStage?.id ? `Week (${developmentStage.weekFrom} - ${developmentStage.maxThreshold ? developmentStage.maxThreshold : developmentStage.weekTo})` : 'Week'}`}
                                value={values.week || ''}
                                onBlur={(e) => {
                                    if (!developmentStage || !values.week) {
                                        setWeekError(undefined)
                                        setWeekToError(undefined)
                                        return;
                                    }

                                    if (values.week < developmentStage.weekFrom || (developmentStage.maxThreshold && values.week > developmentStage.maxThreshold) || (!developmentStage.maxThreshold && values.week > developmentStage.weekTo)) {
                                        setWeekError(`Week should be between ${developmentStage.weekFrom} and ${developmentStage.maxThreshold ? developmentStage.maxThreshold : developmentStage.weekTo}`)
                                        return;
                                    }

                                    setWeekError(undefined)
                                    setWeekToError('Week To is required')
                                }}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value)
                                    const week = Number.isNaN(value) ? undefined : value
                                    setCrawlerSampleQuestionRequest({
                                        ...crawlerSampleQuestionRequest,
                                        week: Number.isNaN(week) ? undefined : week,
                                        weekTo: undefined,
                                    })

                                    values.week = week
                                    values.weekTo = undefined

                                    setWeekToError(undefined)
                                }}
                                error={!!weekError}
                                helperText={weekError}
                                name='week'
                                disabled={!values.developmentStageId}
                            />

                            <TextField
                                variant='filled'
                                type='text'
                                label={`${developmentStage?.id && values.week ? `Week (${values.week} - ${developmentStage.maxThreshold ? developmentStage.maxThreshold : developmentStage.weekTo})` : 'Week To'}`}
                                value={values.weekTo || ''}
                                onBlur={(e) => {
                                    if (!developmentStage || !values.week) {
                                        setWeekToError(undefined)
                                        return;
                                    }

                                    if (!values.weekTo) {
                                        setWeekToError('Week To is required')
                                        return;
                                    }

                                    setWeekToError(undefined)

                                    if (values.weekTo < values.week || (developmentStage.maxThreshold && values.weekTo > developmentStage.maxThreshold) || (!developmentStage.maxThreshold && values.weekTo > developmentStage.weekTo)) {
                                        setWeekToError(`Week To should be between ${values.week} and ${developmentStage.maxThreshold ? developmentStage.maxThreshold : developmentStage.weekTo}`)
                                        return;
                                    }

                                    setWeekToError(undefined)
                                }}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value)
                                    const weekTo = Number.isNaN(value) ? undefined : value
                                    setCrawlerSampleQuestionRequest({
                                        ...crawlerSampleQuestionRequest,
                                        weekTo: weekTo
                                    })

                                    values.weekTo = weekTo
                                }}
                                error={!!weekToError}
                                helperText={weekToError}
                                name='weekTo'
                                disabled={!values.week}
                            />

                            <TextareaAutosizeExt
                                label='Question'
                                onChange={(v) => {
                                    setCrawlerSampleQuestionRequest({
                                        ...crawlerSampleQuestionRequest,
                                        question: v
                                    })

                                    values.question = v
                                }}
                                minRows={10}
                                maxRows={10}
                                value={values.question}
                                error={!!touched.question && !!errors.question}
                                helperText={touched.question && errors.question}
                                name='question'
                            />
                        </Box>

                        <Box
                            display='grid'
                            mt="20px"
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            {crawlerSampleQuestion?.createdBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created By'
                                        value={crawlerSampleQuestion.createdBy}
                                        name='createdBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Created At'
                                        value={crawlerSampleQuestion.createdAt}
                                        name='createdAt'
                                    />
                                </>
                            )}

                            {crawlerSampleQuestion?.updatedBy && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated By'
                                        value={crawlerSampleQuestion.updatedBy}
                                        name='createdBy'
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Updated At'
                                        value={crawlerSampleQuestion.updatedAt}
                                        name='createdAt'
                                    />
                                </>
                            )}
                        </Box>

                        <Box
                            display='flex'
                            justifyContent='end'
                            mt='20px'
                            gap='20px'
                        >
                            <ButtonExt
                                type='submit'
                                value={
                                    crawlerSampleQuestionCreateOrUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    crawlerSampleQuestionCreateOrUpdateMutation.isLoading ||
                                    !!weekError ||
                                    !!weekToError
                                }
                            />

                            {crawlerSampleQuestion?.id && (
                                <ButtonExt
                                    value={
                                        deleteCrawlerSampleQuestionByIdMutation.isLoading
                                            ? 'Deleting'
                                            : 'Delete'
                                    }
                                    onClickEvent={onDelete}
                                    disabled={deleteCrawlerSampleQuestionByIdMutation.isLoading}
                                />
                            )}
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerSampleQuestionConfigurationDetail)