import SecureAxios from "../extensions/SecureAxios";

/**
 * An action to fetch admin crawler public dataset
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const fetchAdminCrawlerPublicDataset = async (searchOptions) => {
    let adminCrawlerPublicDatasetSearchUrl = `/admin/crawler/public/dataset?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`

    if (searchOptions.customSearchOptions?.filteredById) {
        adminCrawlerPublicDatasetSearchUrl += `&filteredById=${searchOptions.customSearchOptions.filteredById}`
    }

    if (searchOptions.customSearchOptions?.filteredByCrawlerId) {
        adminCrawlerPublicDatasetSearchUrl += `&filteredByCrawlerId=${searchOptions.customSearchOptions.filteredByCrawlerId}`
    }

    if (searchOptions.customSearchOptions?.filteredByCrawlerDataId) {
        adminCrawlerPublicDatasetSearchUrl += `&filteredByCrawlerDataId=${searchOptions.customSearchOptions.filteredByCrawlerDataId}`
    }

    const { data } = await SecureAxios.get(adminCrawlerPublicDatasetSearchUrl)
    return data
}

export const deleteCrawlerPublicDatasetById = async (request) => {
    await SecureAxios.delete(`/admin/crawler/public/dataset/${request.id}/delete`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export const deleteCrawlerPublicDatasetByBatchIds = async (ids) => {
    await SecureAxios.delete(`/admin/crawler/public/dataset/delete/batch`, {
        params: { ids: ids.join(",") },
        headers: {
            'Content-Type': 'application/json',
        }
    });
}

export const getCrawlerPublicDatasetStatus = async (crawlerId) => {
    const { data } = await SecureAxios.get(`/admin/crawler/${crawlerId}/public/dataset/update/status`)
    return data
}

export const updateCrawlerPublicDataset = async (crawlerId) => {
    await SecureAxios.post(`/admin/crawler/${crawlerId}/public/dataset/update`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

export const terminateCrawlerPublicDatasetRunningProcess = async (crawlerId) => {
    await SecureAxios.post(`/admin/crawler/${crawlerId}/public/dataset/halt/processing`, {}, {
        headers: {
            'Content-Type': 'application/json',
        }
    })

}

export const searchCrawlerRecommendedPublicDataset = async (searchOptions) => {
    const requestBody = {
        queryVector: searchOptions.customSearchOptions.queryVector,
        topk: searchOptions.customSearchOptions.topk,
        developmentStageIds: [searchOptions.customSearchOptions.developmentStageId],
        includeScore: true,
        includePrompt: true,
        acceptableVectorSearchScore: searchOptions.customSearchOptions.acceptableVectorSearchScore,
        crawlerIds: searchOptions.customSearchOptions.crawlerIds,
        generateEmbeddedAnswer: searchOptions.customSearchOptions.generateEmbeddedAnswer,
        embeddedAnswerProvider: searchOptions.customSearchOptions.embeddedAnswerProvider,
        embeddedAnswerProviderModel: searchOptions.customSearchOptions.embeddedAnswerProviderModel,
        embeddedAnswerProviderMaxTokens: searchOptions.customSearchOptions.embeddedAnswerProviderMaxTokens,
        embeddedAnswerAdditionalPrompt: searchOptions.customSearchOptions.embeddedAnswerAdditionalPrompt,
    }

    if (searchOptions.customSearchOptions?.week && searchOptions.customSearchOptions?.weekTo) {
        requestBody.week = searchOptions.customSearchOptions.week
        requestBody.weekTo = searchOptions.customSearchOptions.weekTo
    } else if (searchOptions.customSearchOptions.week) {
        requestBody.week = searchOptions.customSearchOptions.week
    }

    const { data } = await SecureAxios.post('/crawler/public/dataset/vector/search', requestBody, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return data
}