import React, {useEffect, useState} from 'react'
import {Box, TextField} from '@mui/material'
import {AuthToken} from "../../../../../actions/auth";
import Header from "../../../../../components/Header";
import DataGridFilter, {SearchOptionsProp} from "../../../../../components/DataGridFilter";
import {connect} from "react-redux";
import {
    CrawlerPublicDataset,
    CrawlerPublicDatasetCustomFilterOptions, CrawlerPublicDatasetStatus
} from "../../../../../interfaces/CrawlerPublicDatasetType";
import CrawlerPublicDatasetDetail from "./detail";
import {
    deleteCrawlerPublicDatasetByBatchIds,
    fetchAdminCrawlerPublicDataset, getCrawlerPublicDatasetStatus, terminateCrawlerPublicDatasetRunningProcess,
    updateCrawlerPublicDataset
} from "../../../../../actions/crawlerPublicDataset";
import {useAuthQueryWithQueryFunction} from "../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../interfaces/ErrorType";
import {fetchAllAdminCrawlerConfigurations} from "../../../../../actions/crawler";
import AutocompleteExt from "../../../../../components/Autocomplete";
import {useMutation} from "react-query";
import ButtonExt from "../../../../../components/ButtonExt";
import ErrorMessage from "../../../../../components/ErrorMessage";
import ConfirmationDialog from "../../../../../components/ConfirmationDialog";
import {v4 as uuidv4} from "uuid";
import {Crawler} from "../../../../../interfaces/CrawlerType";

const CrawlerPublicDatasetConfiguration = (props: {user: AuthToken}) => {

    const { user } = props
    const [change, setChange] = useState<string>()

    const [crawlerPublicDatasetComplete, setCrawlerPublicDatasetComplete] = useState<boolean>();
    const [customSearchOptions, setCustomSearchOptions] =
        useState<CrawlerPublicDatasetCustomFilterOptions>({
            filteredById: '',
            filteredByCrawlerId: undefined,
            filteredByCrawlerDataId: '',
        })

    const [customActionBulkIdsRequest, setCustomActionBulkIdsRequest] =
        useState<string[]>([])

    const [showConfirmation, setShowConfirmation] = useState({
        action: '',
        message: '',
        enable: false
    });

    const crawlerConfigurationsQuery = useAuthQueryWithQueryFunction<
        any,
        ApiError,
        any
        >('crawlerConfigurations', fetchAllAdminCrawlerConfigurations, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const getCrawlerPublicDatasetStatusQuery = useAuthQueryWithQueryFunction<string, ApiError, CrawlerPublicDatasetStatus>(
        ['crawlerPublicDatasetStatus', customSearchOptions.filteredByCrawlerId],
        () => getCrawlerPublicDatasetStatus(customSearchOptions.filteredByCrawlerId),
        {
            onSuccess(data) {
                setCrawlerPublicDatasetComplete(!data.currentlyProcessing)
            },
            refetchOnWindowFocus: false,
            enabled: false,
        }
    )

    /**
     * Mutate delete crawler public dataset by batch batch ids
     */
    const deleteCrawlerPublicDatasetByBatchIdsMutation = useMutation<any, ApiError, any>(
        deleteCrawlerPublicDatasetByBatchIds
    )

    /**
     * Mutate update crawler public dataset by crawler id
     */
    const updateCrawlerPublicDatasetByCrawlerIdMutation = useMutation<any, ApiError, any>(
        updateCrawlerPublicDataset
    )

    /**
     * Mutate terminate crawler public dataset running process
     */
    const terminateCrawlerPublicDatasetRunningProcessMutation = useMutation<any, ApiError, any>(
        terminateCrawlerPublicDatasetRunningProcess
    )

    useEffect(() => {
        const checkCrawlerStatus = async () => {
            try {
                await getCrawlerPublicDatasetStatusQuery.refetch();
            } catch (error) {
                console.error('Error fetching crawler status:', error);
            }
        };

        checkCrawlerStatus();
    }, [customSearchOptions.filteredByCrawlerId]);

    if (crawlerConfigurationsQuery.isLoading) {
        return <div>Loading...</div>
    }

    const availableCrawlerOptions = crawlerConfigurationsQuery.data?.data?.content?.map((crawlerConfiguration: any) => {
        return {
            value: crawlerConfiguration.id,
            label: crawlerConfiguration.name,
        }
    });

    const customSearchOptionsRenderer = () => (
        <>
            <TextField
                label='Filtered By Id'
                value={customSearchOptions.filteredById}
                onChange={(e) => setCustomSearchOptions({
                    ...customSearchOptions,
                    filteredById: e.target.value
                })}
            />

            <AutocompleteExt
                name="filteredByCrawlerId"
                multiSelection={false}
                label="Crawler"
                selectedValue={customSearchOptions.filteredByCrawlerId}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByCrawlerId: value,
                    })
                }}
                options={availableCrawlerOptions}
                required={!customSearchOptions.filteredById && !customSearchOptions.filteredByCrawlerDataId}
            />

            <TextField
                label='Filtered By Crawler Data Id'
                value={customSearchOptions.filteredByCrawlerDataId}
                onChange={(e) => setCustomSearchOptions({
                    ...customSearchOptions,
                    filteredByCrawlerDataId: e.target.value
                })}
            />
        </>
    )

    const customActionInputRenderer = () => (
        <>
            <Box style={{ marginBottom: `2em` }}>
                {deleteCrawlerPublicDatasetByBatchIdsMutation.isError && (
                    <ErrorMessage error={deleteCrawlerPublicDatasetByBatchIdsMutation.error} />
                )}

                {deleteCrawlerPublicDatasetByBatchIdsMutation.isError && (
                    <ErrorMessage error={deleteCrawlerPublicDatasetByBatchIdsMutation.error} />
                )}

                {updateCrawlerPublicDatasetByCrawlerIdMutation.isError && (
                    <ErrorMessage error={updateCrawlerPublicDatasetByCrawlerIdMutation.error} />
                )}

                {terminateCrawlerPublicDatasetRunningProcessMutation.isError && (
                    <ErrorMessage error={terminateCrawlerPublicDatasetRunningProcessMutation.error} />
                )}
            </Box>

            <Header title='Bulk Request' titleVariant="h3" subtitle={`${customActionBulkIdsRequest.length} ${customActionBulkIdsRequest.length <= 1 ? 'entry selected' : 'entries selected'} `}  />

            <Box display='flex'
                 gap='10px'>
                {user?.user?.role && user.user.role === 'ADMIN' && (
                    <ButtonExt
                        type='button'
                        value={
                            deleteCrawlerPublicDatasetByBatchIdsMutation.isLoading
                                ? 'Deleting'
                                : 'Bulk Delete'
                        }
                        onClickEvent={() => {
                            setShowConfirmation({
                                action: 'DELETE',
                                message: 'Are you sure you want to delete?',
                                enable: true
                            })
                        }}
                        disabled={
                            deleteCrawlerPublicDatasetByBatchIdsMutation.isLoading ||
                            customActionBulkIdsRequest.length === 0
                        }
                    />
                )}

                {customSearchOptions.filteredByCrawlerId && (
                    <>
                        <ButtonExt
                            type='button'
                            value={
                                (updateCrawlerPublicDatasetByCrawlerIdMutation.isLoading || !crawlerPublicDatasetComplete)
                                    ? 'Syncing...'
                                    : 'Re-Sync'
                            }
                            onClickEvent={() => {
                                setShowConfirmation({
                                    action: 'UPDATE',
                                    message: 'Are you sure you want to re-sync?',
                                    enable: true
                                })
                            }}
                            disabled={
                                updateCrawlerPublicDatasetByCrawlerIdMutation.isLoading ||
                                !crawlerPublicDatasetComplete
                            }
                        />
                        <ButtonExt
                            type='button'
                            value={
                                terminateCrawlerPublicDatasetRunningProcessMutation.isLoading
                                    ? 'Terminating...'
                                    : 'Terminate'
                            }
                            onClickEvent={() => {
                                setShowConfirmation({
                                    action: 'TERMINATE',
                                    message: 'Are you sure you want to terminate the current running process?',
                                    enable: true
                                })
                            }}
                            disabled={
                                terminateCrawlerPublicDatasetRunningProcessMutation.isLoading ||
                                crawlerPublicDatasetComplete
                            }
                        />
                        {!crawlerPublicDatasetComplete && (
                            <ButtonExt
                                type='button'
                                value={getCrawlerPublicDatasetStatusQuery.isLoading ? 'Checking...' : 'Checking'}
                                onClickEvent={() => {
                                    getCrawlerPublicDatasetStatusQuery.refetch()
                                }}
                            />
                        )}
                    </>
                )}

                <ConfirmationDialog open={showConfirmation.enable} message={showConfirmation.message} onConfirm={(confirmed) => {
                    if (confirmed) {
                        switch (showConfirmation.action) {
                            case 'DELETE':
                                onBulkDelete()
                                break
                            case 'UPDATE':
                                onBulkUpdate()
                                break
                            case 'TERMINATE':
                                onTerminate()
                                break
                        }
                    } else {
                        setShowConfirmation({
                            action: '',
                            message: '',
                            enable: false
                        })
                    }
                }} />
            </Box>
        </>
    )

    const expandRow = (row: CrawlerPublicDataset) => (
        <CrawlerPublicDatasetDetail isNew={false} wrapper={row} callback={setChange} editable={true} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAdminCrawlerPublicDataset(searchOptions)
    }

    const onBulkDelete = () => {
        deleteCrawlerPublicDatasetByBatchIdsMutation.mutate(customActionBulkIdsRequest, {
            onSuccess: () => {
                setCustomActionBulkIdsRequest([])
                setShowConfirmation({
                    action: '',
                    message: '',
                    enable: false
                })
                setChange(uuidv4())
            }
        })
    }

    const onBulkUpdate = () => {
        updateCrawlerPublicDatasetByCrawlerIdMutation.mutate(customSearchOptions.filteredByCrawlerId, {
            onSuccess: () => {
                getCrawlerPublicDatasetStatusQuery.refetch()
                setShowConfirmation({
                    action: '',
                    message: '',
                    enable: false
                })
            }
        })
    }

    const onTerminate = () => {
        terminateCrawlerPublicDatasetRunningProcessMutation.mutate(customSearchOptions.filteredByCrawlerId, {
            onSuccess: () => {
                getCrawlerPublicDatasetStatusQuery.refetch()
                setShowConfirmation({
                    action: '',
                    message: '',
                    enable: false
                })
            }
        })
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
        },
        {
            dataField: 'title',
            text: 'Title',
        },
        {
            dataField: 'crawlerId',
            text: 'Crawler',
            converter: (value: any) => {
                return availableCrawlerOptions.find((option: any) => option.value === value)?.label
            }
        },
        {
            dataField: 'crawlerId',
            text: 'Crawler Id',
        },
        {
            dataField: 'crawlerDataId',
            text: 'Crawler Data Id',
        },
        {
            dataField: 'crawlerDataTextId',
            text: 'Crawler Data Text Id',
        },
        {
            dataField: 'week',
            text: 'Week',
        },
        {
            dataField: 'weekTo',
            text: 'Week To',
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Crawler Dataset' />

            <DataGridFilter
                keyField='id'
                multiSelect={true}
                useQueryKey={`admin-crawler-public-dataset`}
                change={change}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                customSearchOptions={customSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                customActionInputRenderer={customActionInputRenderer()}
                resetCustomSearchOptions={(customSearchOptions) => {
                    setCustomSearchOptions(customSearchOptions)
                }}
                searchFilterCols={3}
                disabledMatchAll={true}
                expandRow={expandRow}
                selectedRow={(rows) => {
                    setCustomActionBulkIdsRequest(rows.map((row) => row.id))
                }}
                preloadEnabled={false}
            />
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerPublicDatasetConfiguration)