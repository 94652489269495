export const crawlerTypeOptions = [
    {
        value: 'WEB',
        label: 'Web',
    }
]

export const splitByOptions = [
    {
        value: 'SENTENCES',
        label: 'Sentence'
    },
    {
        value: 'APPROXIMATE_WORDS',
        label: 'Approximate Word'
    },
    {
        value: 'PARAGRAPHS',
        label: 'Paragraph'
    }
]

export const crawlerScheduleOptions = [
    {
        value: 'CONFIGURATION_CHANGE',
        label: 'Configuration Change',
    },
    {
        value: 'WEEKLY',
        label: 'Weekly',
    },
    {
        value: 'FORTNIGHTLY',
        label: 'Fortnightly',
    },
    {
        value: 'MONTHLY',
        label: 'Monthly',
    }
]

export const keywordMatchedByOptions = [
    {
        value: 'TITLE',
        label: 'Title',
    },
    {
        value: 'TEXT',
        label: 'Text',
    },
]

export const crawlerTextRegexExtractByFieldOptions = [
    {
        value: 'URL',
        label: 'Url',
    },
    {
        value: 'TITLE',
        label: 'Title',
    },
    {
        value: 'TEXT',
        label: 'Text',
    },
]

export const filteredChunksToExcludeUndesirableTextBasedOnNlpOptions = [
    {
        value: 'PERSON',
        label: 'Person',
    },
]

export const filteredByDataStatusOptions = [
    {
        value: 'PUBLISHED',
        label: 'Published',
    },
    {
        value: 'WITHDRAWN',
        label: 'Withdrawn',
    },
]