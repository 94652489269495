import React, {useState} from 'react'
import {Box} from '@mui/material'
import DataGridFilter, { SearchOptionsProp } from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import {Crawler, CrawlerCustomFilterOptions} from "../../../interfaces/CrawlerType";
import CrawlerConfigurationDetail from "./detail";
import {fetchAdminCrawlerConfigurations} from "../../../actions/crawler";
import {connect} from "react-redux";
import {AuthToken} from "../../../actions/auth";
import AutocompleteExt from "../../../components/Autocomplete";
import {useAuthQueryWithQueryFunction} from "../../../extensions/UseAuthQuery";
import {ApiError} from "../../../interfaces/ErrorType";
import {fetchDevelopmentStages, resetDevelopmentStage} from "../../../actions/developmentStage";
import {filteredByDataStatusOptions} from "../../../share/CrawlerConstants";
import {useMutation} from "react-query";
import ButtonExt from "../../../components/ButtonExt";

const CrawlerConfiguration = (props: {user: AuthToken}) => {

    const [change, setChange] = useState<string>()
    const { user } = props

    const [customSearchOptions, setCustomSearchOptions] =
        useState<CrawlerCustomFilterOptions>({
            filteredByDataStatus: undefined,
            filteredByDevelopmentStageIds: [],
        })

    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Mutate function to reset development stage
     */
    const resetDevelopmentStageMutation = useMutation<any, ApiError, any>(
        resetDevelopmentStage,
    )

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    });

    const onDevelopmentStageReset = () => {
        resetDevelopmentStageMutation.mutate({}, {
            onSuccess: () => {
                developmentStageQuery.refetch()
            }
        })
    }

    const customSearchOptionsRenderer = () => (
        <>
            <Box display="grid" gridTemplateColumns="3fr 1fr">
                <AutocompleteExt
                    name="filteredByDevelopmentStageIds"
                    multiSelection={true}
                    label="Development Stages"
                    selectedValue={customSearchOptions.filteredByDevelopmentStageIds}
                    onSelect={(value) => {
                        if (value.length == 1) {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                filteredByDevelopmentStageIds: value,
                            })
                        } else {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                filteredByDevelopmentStageIds: value,
                            })
                        }
                    }}
                    options={availableDevelopmentStageOptions}
                />

                <Box>
                    <ButtonExt
                        style={{marginTop: '20px'}}
                        type="button"
                        onClickEvent={onDevelopmentStageReset}
                        value={
                            resetDevelopmentStageMutation.isLoading
                                ? 'Loading...'
                                : 'Refresh'
                        }
                    />
                </Box>
            </Box>


            <AutocompleteExt
                name="filteredByDataStatus"
                multiSelection={false}
                label="Data Status"
                selectedValue={customSearchOptions.filteredByDataStatus}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        filteredByDataStatus: value,
                    })
                }}
                options={filteredByDataStatusOptions}
            />
        </>
    )

    const expandRow = (row: Crawler) => (
        <CrawlerConfigurationDetail isNew={false} wrapper={row} callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAdminCrawlerConfigurations(searchOptions)
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: false,
        },
        {
            dataField: 'enabled',
            text: 'Enabled',
            sort: false,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: false,
        },
        {
            dataField: 'dataStatus',
            text: 'Data Status',
            sort: false,
        },
        {
            dataField: 'updatedAt',
            text: 'Latest Change',
            sort: true,
        }
    ]

    return (
        <Box m='20px'>
            <Header title='Crawler Configuration' />

            <DataGridFilter
                keyField='id'
                useQueryKey={`admin-crawler-configuration`}
                change={change}
                columns={columns}
                customSearchOptions={customSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                resetCustomSearchOptions={setCustomSearchOptions}
                searchFilterCols={2}
                disabledMatchAll={true}
                createPageUrl={user?.user?.role === 'ADMIN' ? "/crawler/create" : ""}
                hasCreatePermission={user?.user?.role === 'ADMIN'}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerConfiguration)