import { combineReducers } from 'redux'
import tableData from './reducer/tableData'
import tabsIndexes from './reducer/tabs'
import user from './reducer/user'

/**
 * Contains all the store reducers state.
 * Refer to store.js and /reducer/*
 */
export default combineReducers({
    user,
    tableData,
    tabsIndexes,
})
