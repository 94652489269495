import store from '../redux/store'
import { UNSET_UESR } from '../redux/actionType'

export const loadCurrentConfigurations = () => {
    return async () => {}
}

export const removeAllConfigurations = () => {
    store.dispatch({
        type: UNSET_UESR,
    })
}
