import Axios from './Axios'
import {getValueByKey, removeKey, updateValueByKey} from './EncryptStorage'
import { NEUROFROG_REACT_AUTH_TOKEN } from '../share/AuthConstant'
import { refresh } from '../actions/auth'

/**
 * Intercept each secure request, and attach a bearer token if it presents
 */
Axios.interceptors.request.use(
    async (config) => {
        const token = getValueByKey(NEUROFROG_REACT_AUTH_TOKEN)
        if (token && token.token) {
            config.headers['Authorization'] = `Bearer ${token.token}`
            return config
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// Response interceptor for API calls
Axios.interceptors.response.use(
    (config) => {
        return config
    },
    async function (error) {
        const originalRequest = error.config
        const token = getToken()
        if (
            error.response.status === 401 &&
            hasToken() &&
            !originalRequest._retry
        ) {
            originalRequest._retry = true
            const newToken = await refresh(token)
            if (newToken) {
                token['token'] = newToken.token
                token['accessToken'] = newToken.accessToken
                token['refreshToken'] = newToken.refreshToken
                token['externalId'] = newToken.externalId

                if (hasToken()) {
                    updateToken(token)

                    originalRequest.headers[
                        'Authorization'
                        ] = `Bearer ${token.token}`
                    originalRequest.headers = JSON.parse(
                        JSON.stringify(originalRequest.headers || {})
                    )

                    return Axios(originalRequest)
                }
            }
        }
        return Promise.reject(error)
    }
)

function hasToken() {
    const token = getValueByKey(NEUROFROG_REACT_AUTH_TOKEN)
    if (
        token &&
        token.token &&
        token.accessToken &&
        token.refreshToken &&
        token.externalId
    ) {
        return true
    }
    return false
}

function getToken() {
    return getValueByKey(NEUROFROG_REACT_AUTH_TOKEN)
}

function updateToken(newToken) {
    updateValueByKey(NEUROFROG_REACT_AUTH_TOKEN, newToken)
}

export function removeToken() {
    if (getToken()) {
        console.log('Refresh token has expired...')
        removeKey(NEUROFROG_REACT_AUTH_TOKEN)
    }
}

export default Axios
