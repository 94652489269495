export const textRegexExtractorValueTypeOptions = [
    {
        value: 'NUMBER',
        label: 'Number',
    },
    {
        value: 'NUMBER_RANGE',
        label: 'Number Range',
    },
]

export const converterTypeOptions = [
    {
        value: 'MONTH_TO_WEEK',
        label: 'Month to Week',
    },
    {
        value: 'YEAR_TO_WEEK',
        label: 'Year to Week',
    }
]