import React, {FunctionComponent, useEffect, useState} from 'react'
import {Box, IconButton, InputLabel, TextField, Tooltip} from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useMutation } from 'react-query'
import { ApiError } from '../../../../interfaces/ErrorType'
import AutocompleteExt from '../../../../components/Autocomplete'
import ButtonExt from '../../../../components/ButtonExt'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Header from '../../../../components/Header'
import ErrorMessage from '../../../../components/ErrorMessage'
import { useNavigate } from 'react-router-dom'
import {Crawler, CrawlerRequest, CrawlerWrapper} from "../../../../interfaces/CrawlerType";
import {
    deleteCrawler,
    deleteCrawlerData,
    getCrawler,
    publishCrawler,
    saveCrawler,
    withdrawCrawler
} from "../../../../actions/crawler";
import {
    crawlerScheduleOptions,
    crawlerTextRegexExtractByFieldOptions,
    crawlerTypeOptions, filteredChunksToExcludeUndesirableTextBasedOnNlpOptions, splitByOptions
} from "../../../../share/CrawlerConstants";
import CheckboxExt from "../../../../components/Checkbox";
import CrawlerDataDetail from "../data/detail";
import AccordionExt from "../../../../components/AccordionExt";
import {findCrawlerTextRegexExtractorsByNumberValueType} from "../../../../actions/crawlerTextRegexExtractor";
import {useAuthQueryWithQueryFunction} from "../../../../extensions/UseAuthQuery";
import {CrawlerTextRegexExtractor} from "../../../../interfaces/CrawlerTextRegexExtractorType";
import SelectExt from "../../../../components/Select";
import TextareaAutosizeExt from "../../../../components/TextareaAutosize";
import {
    WebCrawlerElementDistanceCalculatorRequest, WebCrawlerElementDistanceCalculatorResponse
} from "../../../../interfaces/WebCrawlerElementDistanceCalculatorType";
import {calculateElementDistance} from "../../../../actions/webCrawlerElementDistanceCalculator";
import {connect} from "react-redux";
import TagInputExt from "../../../../components/TagInput";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import {fetchDevelopmentStages} from "../../../../actions/developmentStage";
import {DevelopmentStage} from "../../../../interfaces/KnowledgeHub";
import store from "../../../../redux/store";
import {UPDATE_TABLE_DATA_ITEM} from "../../../../redux/actionType";
import { v4 as uuidv4 } from 'uuid'
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import {CrawlerPublicDatasetStatus} from "../../../../interfaces/CrawlerPublicDatasetType";
import {getCrawlerPublicDatasetStatus} from "../../../../actions/crawlerPublicDataset";

const crawlerSchema = yup.object().shape({
    name: yup.string().required('required'),
    domainUrl: yup.string().required('required'),
    url: yup.string()
        .required('required')
        .test(
            'is-valid-url',
            'url must start with domainUrl',
            function(value) {
                const { domainUrl } = this.parent;
                if (typeof value !== 'string') {
                    return false;
                }
                return value.startsWith(domainUrl);
            }
        ),
    withinUrl: yup.boolean().optional(),
    developmentStageIds: yup.array().required().min(1),
    type: yup.string().required('required'),
    enabled: yup.boolean().optional(),
    schedule: yup.string().required(),
    metadata: yup.object().shape({
        htmlTagSelector: yup.string().required(),
        htmlContentSelector: yup.string().required(),
        allowedHeaderToTextElementDistance: yup.number().required().min(3),
        allowedTextToTextElementDistance: yup.number().required().min(2),
        splitBy: yup.string().required(),
        splitByLimit: yup.number().required().min(1),
        recursiveCrawl: yup.boolean().optional(),
        allowedMaximumDepthToCrawl: yup.number().when('recursiveCrawl', {
            is: (recursiveCrawl: boolean) => recursiveCrawl === true,
            then: yup.number().required("required").min(2).max(7),
        }),
        allowedMaximumPagesToFetch: yup.number().when('recursiveCrawl', {
            is: (recursiveCrawl: boolean) => recursiveCrawl === true,
            then: yup.number().required("required").min(1).max(10000),
        }),
        crawlerTextRegexExtractorIds: yup.array().optional(),
        crawlerTextRegexExtractByFields: yup.array().when('crawlerTextRegexExtractorIds', {
            is: (crawlerTextRegexExtractorIds: string[]) => crawlerTextRegexExtractorIds.length > 0,
            then: yup.array().required("required").min(1),
        })
    }),
    enableReprocess: yup.boolean().optional(),
    filteredChunksToExcludeUndesirablePatterns: yup.array().optional(),
    filteredChunksToExcludeUndesirableTextBasedOnNlp: yup.array().optional(),
    acceptableMinChunkSizeInWords: yup.number().optional(),
    filteredBySpecialCharacters: yup.boolean().optional(),
})

const CrawlerConfigurationDetail: FunctionComponent<CrawlerWrapper> = ({
                                                                           user,
                                                                           isNew,
                                                                           wrapper,
                                                                           callback,
                                                                      }) => {

    const navigate = useNavigate()
    const [crawler, setCrawler] = useState<Crawler | undefined>(wrapper)
    const [hasCrawlerData, setHasCrawlerData] = useState<boolean>(false)
    const [refreshCrawlerData, setRefreshCrawlerData] = useState<boolean>(false)
    const [crawlerRequest, setCrawlerRequest] = useState<CrawlerRequest>(wrapper?.id ? {
        id: wrapper!!.id,
        name: wrapper!!.name,
        domainUrl: wrapper!!.domainUrl,
        url: wrapper!!.url,
        withinUrl: wrapper!!.withinUrl,
        developmentStageIds: wrapper!!.developmentStageIds,
        type: wrapper!!.type,
        enabled: wrapper!!.enabled,
        schedule: wrapper!!.schedule,
        metadata: {
            htmlTagSelector: wrapper!!.metadata.htmlTagSelector,
            htmlContentSelector: wrapper!!.metadata.htmlContentSelector,
            allowedHeaderToTextElementDistance: wrapper!!.metadata.allowedHeaderToTextElementDistance,
            allowedTextToTextElementDistance: wrapper!!.metadata.allowedTextToTextElementDistance,
            splitBy: wrapper!!.metadata.splitBy,
            splitByLimit: wrapper!!.metadata.splitByLimit,
            recursiveCrawl: wrapper!!.metadata.recursiveCrawl,
            allowedMaximumDepthToCrawl: wrapper!!.metadata.allowedMaximumDepthToCrawl,
            allowedMaximumPagesToFetch: wrapper!!.metadata.allowedMaximumPagesToFetch,
            crawlerTextRegexExtractorIds: wrapper!!.metadata.crawlerTextRegexExtractorIds ? wrapper!!.metadata.crawlerTextRegexExtractorIds : [],
            crawlerTextRegexExtractByFields: wrapper!!.metadata.crawlerTextRegexExtractByFields ? wrapper!!.metadata.crawlerTextRegexExtractByFields : [],
        },
        enableReprocess: wrapper!!.enableReprocess,
        filteredChunksToExcludeUndesirablePatterns: wrapper!!.filteredChunksToExcludeUndesirablePatterns.length > 0 ? wrapper!!.filteredChunksToExcludeUndesirablePatterns.flatMap(filteredChunksToExcludeUndesirablePattern => filteredChunksToExcludeUndesirablePattern.split("|")) : [],
        filteredChunksToExcludeUndesirableTextBasedOnNlp: wrapper!!.filteredChunksToExcludeUndesirableTextBasedOnNlp,
        acceptableMinChunkSizeInWords: wrapper!!.acceptableMinChunkSizeInWords,
        filteredBySpecialCharacters: wrapper!!.filteredBySpecialCharacters,
    } : {
        id: undefined,
        name: '',
        domainUrl: '',
        url: '',
        withinUrl: false,
        developmentStageIds: [],
        type: 'WEB',
        enabled: true,
        schedule: 'CONFIGURATION_CHANGE',
        metadata: {
            htmlTagSelector: '',
            htmlContentSelector: '',
            allowedHeaderToTextElementDistance: 3,
            allowedTextToTextElementDistance: 2,
            splitBy: splitByOptions[0].value,
            splitByLimit: 3,
            recursiveCrawl: false,
            allowedMaximumDepthToCrawl: 1,
            allowedMaximumPagesToFetch: 1,
            crawlerTextRegexExtractorIds: [],
            crawlerTextRegexExtractByFields: [],
        },
        enableReprocess: true,
        filteredChunksToExcludeUndesirablePatterns: [],
        filteredChunksToExcludeUndesirableTextBasedOnNlp: [],
        acceptableMinChunkSizeInWords: undefined,
        filteredBySpecialCharacters: false,
    })

    const [crawlerTextRegexExtractorOptions, setCrawlerTextRegexExtractorOptions] = useState<any[]>([])
    const [developmentStages, setDevelopmentStages] = useState<any[]>([])
    const [webCrawlerElementDistanceCalculatorRequest, setWebCrawlerElementDistanceCalculatorRequest] = useState<WebCrawlerElementDistanceCalculatorRequest>({
        urls: [],
        htmlTagSelector: crawlerRequest.metadata.htmlTagSelector,
        htmlContentSelector: crawlerRequest.metadata.htmlContentSelector,
    })
    const [showConfirmation, setShowConfirmation] = useState({
        action: "",
        message: "",
        enable: false
    });
    const [crawlerPublicDatasetComplete, setCrawlerPublicDatasetComplete] = useState<boolean>();

    /**
     * Fetch crawler text regex extractors data query
     */
    const crawlerTextRegexExtractorsByNumberValueTypeQuery = useAuthQueryWithQueryFunction<undefined,
        ApiError,
        CrawlerTextRegexExtractor[]>('crawler-text-regex-extractors-by-number-value-type', findCrawlerTextRegexExtractorsByNumberValueType, {
        onSuccess(data) {
            setCrawlerTextRegexExtractorOptions(data.map(each => {
                return {
                    value: each.id,
                    label: each.name
                }
            }))
        },
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const getCrawlerQuery = useAuthQueryWithQueryFunction<string, ApiError, Crawler>(
        ['crawler', crawlerRequest.id!!],
        () => getCrawler(crawlerRequest.id!!),
        {
            onSuccess(data) {
                setCrawler(data)
            },
            refetchOnWindowFocus: false,
            enabled: false,
        }
    )

    const getCrawlerPublicDatasetStatusQuery = useAuthQueryWithQueryFunction<string, ApiError, CrawlerPublicDatasetStatus>(
        ['crawlerPublicDatasetStatus', crawler?.id],
        () => getCrawlerPublicDatasetStatus(crawler?.id!!),
        {
            onSuccess(data) {
                setCrawlerPublicDatasetComplete(!data.currentlyProcessing)
            },
            refetchOnWindowFocus: false,
            enabled: false,
        }
    )

    /**
     * Invoke an action to create/ update crawler
     * @param {*} e - event
     */
    const onSave = (values: CrawlerRequest) => {
        const mergeFilteredChunksToExcludeUndesirablePatterns = values.filteredChunksToExcludeUndesirablePatterns.join("|")
        const updateValues = {
            ...values,
            filteredChunksToExcludeUndesirablePatterns: mergeFilteredChunksToExcludeUndesirablePatterns ? [mergeFilteredChunksToExcludeUndesirablePatterns] : []
        }

        crawlerCreateOrUpdateMutation.mutate(updateValues, {
            onSuccess: (data) => {
                const crawlerRequest = {
                    id: data.id,
                    domainUrl: data.domainUrl,
                    url: data.url,
                    withinUrl: data.withinUrl,
                    developmentStageIds: data.developmentStageIds,
                    name: data.name,
                    type: data.type,
                    enabled: data.enabled,
                    schedule: data.schedule,
                    metadata: {
                        htmlTagSelector: data.metadata.htmlTagSelector,
                        htmlContentSelector: data.metadata.htmlContentSelector,
                        allowedHeaderToTextElementDistance: data.metadata.allowedHeaderToTextElementDistance,
                        allowedTextToTextElementDistance: data.metadata.allowedTextToTextElementDistance,
                        splitBy: data.metadata.splitBy,
                        splitByLimit: data.metadata.splitByLimit,
                        recursiveCrawl: data.metadata.recursiveCrawl,
                        allowedMaximumDepthToCrawl: data.metadata.allowedMaximumDepthToCrawl,
                        allowedMaximumPagesToFetch: data.metadata.allowedMaximumPagesToFetch,
                        crawlerTextRegexExtractorIds: data.metadata.crawlerTextRegexExtractorIds,
                        crawlerTextRegexExtractByFields: data.metadata.crawlerTextRegexExtractByFields,
                    },
                    enableReprocess: data.enableReprocess,
                    filteredChunksToExcludeUndesirablePatterns: data.filteredChunksToExcludeUndesirablePatterns.length > 0 ? data.filteredChunksToExcludeUndesirablePatterns.flatMap(filteredChunksToExcludeUndesirablePattern => filteredChunksToExcludeUndesirablePattern.split("|")) : [],
                    filteredChunksToExcludeUndesirableTextBasedOnNlp: data.filteredChunksToExcludeUndesirableTextBasedOnNlp,
                    acceptableMinChunkSizeInWords: data.acceptableMinChunkSizeInWords,
                    filteredBySpecialCharacters: data.filteredBySpecialCharacters,
                }
                setCrawler(data)
                setCrawlerRequest(crawlerRequest)
                values = crawlerRequest

                if (data?.status === 'PROCESSING' || data?.status === 'UPDATING') {
                    // Retries till the crawler is processed
                    const interval = setInterval(async () => {
                        try {
                            const result = await getCrawlerQuery.refetch();
                            if (result.data?.status !== 'PROCESSING' && result.data?.status !== 'UPDATING') {
                                clearInterval(interval);
                                setRefreshCrawlerData(true)
                                store.dispatch({
                                    type: UPDATE_TABLE_DATA_ITEM,
                                    payload: { key: 'admin-crawler-configuration', keyField: 'id', data: result.data },
                                })
                            }
                        } catch (error) {
                            clearInterval(interval)
                        }
                    }, 10000)
                } else {
                    setRefreshCrawlerData(true)
                }
            },
        })
    }

    const onCalculate = (values: CrawlerRequest) => {
        elementDistanceCalculateMutation.mutate(webCrawlerElementDistanceCalculatorRequest, {
            onSuccess: (data) => {
                if (data.maxHeaderToTextElementDistance > values.metadata.allowedHeaderToTextElementDistance!!) {
                    if (crawler) {
                        setCrawler({
                            ...crawler,
                            metadata: {
                                ...crawler.metadata,
                                allowedHeaderToTextElementDistance: data.maxHeaderToTextElementDistance
                            }
                        })
                    }

                    const updateMetadata = {
                        ...values.metadata,
                        allowedHeaderToTextElementDistance: data.maxHeaderToTextElementDistance
                    }

                    setCrawlerRequest({
                        ...values,
                        metadata: updateMetadata
                    })

                    values.metadata = updateMetadata
                }

                if (data.maxTextToTextElementDistance > values.metadata.allowedTextToTextElementDistance!!) {
                    if (crawler) {
                        setCrawler({
                            ...crawler,
                            metadata: {
                                ...crawler.metadata,
                                allowedTextToTextElementDistance: data.maxTextToTextElementDistance
                            }
                        })
                    }

                    const updateMetadata = {
                        ...values.metadata,
                        allowedTextToTextElementDistance: data.maxTextToTextElementDistance
                    }

                    setCrawlerRequest({
                        ...values,
                        metadata: updateMetadata
                    })

                    values.metadata = updateMetadata
                }
            }
        })
    }

    const onPublish = () => {
        crawlerPublishMutation.mutate(crawler!!.id, {
            onSuccess: () => {
                if (crawler) {
                    setCrawler({
                        ...crawler,
                        dataStatus: 'PUBLISHING'
                    })

                    // Retries till the crawler is processed
                    const interval = setInterval(async () => {
                        try {
                            const result = await getCrawlerQuery.refetch();
                            if (result.data?.dataStatus !== 'PUBLISHING') {
                                clearInterval(interval);
                                store.dispatch({
                                    type: UPDATE_TABLE_DATA_ITEM,
                                    payload: { key: 'admin-crawler-configuration', keyField: 'id', data: result.data },
                                })
                            }
                        } catch (error) {
                            clearInterval(interval)
                        }
                    }, 5000)
                }

                setShowConfirmation({
                    action: "",
                    message: "",
                    enable: false
                })
            }
        })
    }

    const onWithdraw = () => {
        crawlerWithdrawMutation.mutate(crawler!!.id, {
            onSuccess: () => {
                if (crawler) {
                    const crawlerUpdate = {
                        ...crawler,
                        dataStatus: 'WITHDRAWN'
                    }
                    setCrawler(crawlerUpdate)

                    store.dispatch({
                        type: UPDATE_TABLE_DATA_ITEM,
                        payload: { key: 'admin-crawler-configuration', keyField: 'id', data: crawlerUpdate },
                    })
                }

                setShowConfirmation({
                    action: "",
                    message: "",
                    enable: false
                })
            }
        })
    }

    const onDelete = () => {
        deleteCrawlerMutation.mutate(crawler!!.id, {
            onSuccess: () => {
                setShowConfirmation({
                    action: "",
                    message: "",
                    enable: false
                })
                if (callback) {
                    callback(uuidv4())
                }
            }
        })
    }

    const onDeleteData = () => {
        deleteCrawlerDataMutation.mutate(crawler!!.id, {
            onSuccess: () => {
                setShowConfirmation({
                    action: "",
                    message: "",
                    enable: false
                })
            }
        })
    }

    const onRefresh = () => {
        getCrawlerQuery.refetch()
    }

    /**
     * Mutate crawler create/ update
     */
    const crawlerCreateOrUpdateMutation = useMutation<Crawler, ApiError, CrawlerRequest>(
        saveCrawler,
    )

    /**
     * Mutate publish crawler
     */
    const crawlerPublishMutation = useMutation<any, ApiError, any>(
        publishCrawler,
    )

    /**
     * Mutate withdraw crawler
     */
    const crawlerWithdrawMutation = useMutation<any, ApiError, any>(
        withdrawCrawler,
    )

    /**
     * Mutate delete crawler
     */
    const deleteCrawlerMutation = useMutation<any, ApiError, any>(
        deleteCrawler,
    )

    /**
     * Mutate delete crawler data
     */
    const deleteCrawlerDataMutation = useMutation<any, ApiError, any>(
        deleteCrawlerData,
    )

    /**
     * Fetch development stages data query
     */
    const developmentStagesQuery = useAuthQueryWithQueryFunction<undefined, ApiError, DevelopmentStage[]>(
        'development-stages',
        fetchDevelopmentStages,
        {
            onSuccess(data) {
                setDevelopmentStages(data.map(each => {
                    return {
                        value: each.id,
                        label: each.type
                    }
                }))
            },
            refetchOnWindowFocus: false,
            enabled: true,
        }
    )

    /**
     * Mutate calculate element distance
     */
    const elementDistanceCalculateMutation = useMutation<WebCrawlerElementDistanceCalculatorResponse, ApiError, WebCrawlerElementDistanceCalculatorRequest>(
        calculateElementDistance,
    )

    useEffect(() => {
        const checkCrawlerStatus = async () => {
            try {
                if (crawler?.dataStatus === 'PUBLISHING' || crawler?.dataStatus === 'PUBLISHED') {
                    await getCrawlerPublicDatasetStatusQuery.refetch();
                }
            } catch (error) {
                console.error('Error fetching crawler status:', error);
            }
        };

        checkCrawlerStatus();
    }, [crawler?.dataStatus]);

    if (crawlerCreateOrUpdateMutation.isSuccess && isNew) {
        navigate(`/crawler`)
    }

    if (crawlerTextRegexExtractorsByNumberValueTypeQuery.isLoading || developmentStagesQuery.isLoading) {
        return <InputLabel>Loading...</InputLabel>
    }

    /**
     * Page containing crawler detail page
     */
    return (
        <Box m='20px'>
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/crawler`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create New Crawler" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {crawlerCreateOrUpdateMutation.isError && (
                    <ErrorMessage error={crawlerCreateOrUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={crawlerRequest}
                validationSchema={crawlerSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display='grid'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            {values.id && (
                                <TextField
                                    variant='filled'
                                    type='text'
                                    label='Id'
                                    value={values.id}
                                    name='id'
                                />
                            )}

                            <TextField
                                variant="filled"
                                type="text"
                                label="Name"
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                disabled={user?.user?.role !== 'ADMIN'}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Domain Url"
                                onChange={handleChange}
                                value={values.domainUrl}
                                name="domainUrl"
                                error={!!touched.domainUrl && !!errors.domainUrl}
                                helperText={touched.domainUrl && errors.domainUrl}
                                disabled={!isNew}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Url"
                                onChange={handleChange}
                                value={values.url}
                                name="url"
                                error={!!touched.url && !!errors.url}
                                helperText={touched.url && errors.url}
                                disabled={!isNew}
                            />

                            <CheckboxExt
                                label="Within Url"
                                onChangeEvent={handleChange}
                                value={values.withinUrl}
                                name="withinUrl"
                                editable={user?.user?.role === 'ADMIN'}
                            />

                            <AutocompleteExt
                                name='developmentStageIds'
                                multiSelection={true}
                                label='Development Stage'
                                selectedValue={values.developmentStageIds}
                                options={developmentStages}
                                onSelect={(v) => {
                                    setCrawlerRequest({
                                        ...values,
                                        developmentStageIds: v,
                                    })
                                    values.developmentStageIds = v
                                }}
                                error={!!touched.developmentStageIds && !!errors.developmentStageIds}
                                helperText={touched.developmentStageIds && errors.developmentStageIds}
                                editable={user?.user?.role === 'ADMIN'}
                            />
                        </Box>

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            {isNew && (
                                <>
                                    <AutocompleteExt
                                        name='type'
                                        multiSelection={false}
                                        label='Type'
                                        selectedValue={values.type}
                                        options={crawlerTypeOptions}
                                        onSelect={(v) => {
                                            setCrawlerRequest({
                                                ...values,
                                                type: v,
                                            })
                                            values.type = v
                                        }}
                                        disableUnselectAll={true}
                                        error={!!touched.type && !!errors.type}
                                        helperText={touched.type && errors.type}
                                        editable={user?.user?.role === 'ADMIN'}
                                    />
                                </>
                            )}

                            <AutocompleteExt
                                name='schedule'
                                multiSelection={false}
                                label='Schedule'
                                selectedValue={values.schedule}
                                options={crawlerScheduleOptions}
                                onSelect={(v) => {
                                    setCrawlerRequest({
                                        ...values,
                                        schedule: v,
                                    })
                                    values.schedule = v
                                }}
                                disableUnselectAll={true}
                                error={!!touched.schedule && !!errors.schedule}
                                helperText={touched.schedule && errors.schedule}
                                editable={user?.user?.role === 'ADMIN'}
                            />

                            {values.type === 'WEB' && (
                                <>
                                    <TextField
                                        variant="filled"
                                        type="text"
                                        label="HTML Tag Selector"
                                        onChange={(event) => {
                                            setCrawlerRequest({
                                                ...values,
                                                metadata: {
                                                    ...values.metadata,
                                                    htmlTagSelector: event.target.value
                                                }
                                            })

                                            setWebCrawlerElementDistanceCalculatorRequest({
                                                ...webCrawlerElementDistanceCalculatorRequest,
                                                htmlTagSelector: event.target.value
                                            })

                                            values.metadata = {
                                                ...values.metadata,
                                                htmlTagSelector: event.target.value
                                            }
                                        }}
                                        value={values.metadata.htmlTagSelector}
                                        name="metadata.htmlTagSelector"
                                        error={!!touched.metadata?.htmlTagSelector && !!errors.metadata?.htmlTagSelector}
                                        helperText={touched.metadata?.htmlTagSelector && errors.metadata?.htmlTagSelector}
                                        disabled={user?.user?.role !== 'ADMIN'}
                                    />
                                    <TextField
                                        variant="filled"
                                        type="text"
                                        label="HTML Content Selector"
                                        onChange={(event) => {
                                            setCrawlerRequest({
                                                ...values,
                                                metadata: {
                                                    ...values.metadata,
                                                    htmlContentSelector: event.target.value
                                                }
                                            })

                                            setWebCrawlerElementDistanceCalculatorRequest({
                                                ...webCrawlerElementDistanceCalculatorRequest,
                                                htmlContentSelector: event.target.value
                                            })

                                            values.metadata = {
                                                ...values.metadata,
                                                htmlContentSelector: event.target.value
                                            }
                                        }}
                                        value={values.metadata.htmlContentSelector}
                                        name="metadata.htmlContentSelector"
                                        error={!!touched.metadata?.htmlContentSelector && !!errors.metadata?.htmlContentSelector}
                                        helperText={touched.metadata?.htmlContentSelector && errors.metadata?.htmlContentSelector}
                                        disabled={user?.user?.role !== 'ADMIN'}
                                    />
                                    <AutocompleteExt
                                        name='metadata'
                                        multiSelection={false}
                                        label='Split By'
                                        selectedValue={values.metadata.splitBy}
                                        options={splitByOptions}
                                        onSelect={(v) => {
                                            const splitByLimit = (v === 'APPROXIMATE_WORDS') ? 50 : 3
                                            setCrawlerRequest({
                                                ...values,
                                                metadata: {
                                                    ...values.metadata,
                                                    splitBy: v,
                                                    splitByLimit: splitByLimit
                                                }
                                            })
                                            values.metadata = {
                                                ...values.metadata,
                                                splitBy: v,
                                                splitByLimit: splitByLimit
                                            }
                                        }}
                                        disableUnselectAll={true}
                                        error={!!touched.metadata?.splitBy && !!errors.metadata?.splitBy}
                                        helperText={touched.metadata?.splitBy && errors.metadata?.splitBy}
                                        editable={user?.user?.role === 'ADMIN'}
                                    />
                                    <TextField
                                        variant="filled"
                                        type="number"
                                        label="Split By Limit"
                                        onChange={handleChange}
                                        value={values.metadata.splitByLimit}
                                        name="metadata.splitByLimit"
                                        error={!!touched.metadata?.splitByLimit && !!errors.metadata?.splitByLimit}
                                        helperText={touched.metadata?.splitByLimit && errors.metadata?.splitByLimit}
                                        disabled={user?.user?.role !== 'ADMIN'}
                                    />
                                    <TextField
                                        variant="filled"
                                        type="number"
                                        label="Allowed Header To Text Element Distance"
                                        onChange={handleChange}
                                        value={values.metadata.allowedHeaderToTextElementDistance}
                                        name="metadata.allowedHeaderToTextElementDistance"
                                        error={!!touched.metadata?.allowedHeaderToTextElementDistance && !!errors.metadata?.allowedHeaderToTextElementDistance}
                                        helperText={touched.metadata?.allowedHeaderToTextElementDistance && errors.metadata?.allowedHeaderToTextElementDistance}
                                        disabled={user?.user?.role !== 'ADMIN'}
                                    />
                                    <TextField
                                        variant="filled"
                                        type="number"
                                        label="Allowed Text To Text Element Distance"
                                        onChange={handleChange}
                                        value={values.metadata.allowedTextToTextElementDistance}
                                        name="metadata.allowedTextToTextElementDistance"
                                        error={!!touched.metadata?.allowedTextToTextElementDistance && !!errors.metadata?.allowedTextToTextElementDistance}
                                        helperText={touched.metadata?.allowedTextToTextElementDistance && errors.metadata?.allowedTextToTextElementDistance}
                                        disabled={user?.user?.role !== 'ADMIN'}
                                    />
                                </>
                            )}
                        </Box>

                        {user?.user?.role && user.user.role === 'ADMIN' && values.type === 'WEB' && values.metadata.htmlTagSelector && values.metadata.htmlContentSelector && (
                            <Box
                                display='grid'
                                mt='20px'
                                gap='30px'
                                gridTemplateColumns='repeat(1, minmax(0,1fr))'
                            >
                                <AccordionExt
                                    title="Calculate Element Distance by Example"
                                    component={
                                        <>
                                            <Box style={{ marginBottom: `2em` }}>
                                                {elementDistanceCalculateMutation.isError && (
                                                    <ErrorMessage error={elementDistanceCalculateMutation.error} />
                                                )}
                                            </Box>
                                            <Box>
                                                <TextareaAutosizeExt
                                                    label="Urls"
                                                    name="urls"
                                                    onChange={(v) => {
                                                        if (v && v.length > 0) {
                                                            const urls = v.split(/\r?\n/);
                                                            setWebCrawlerElementDistanceCalculatorRequest({
                                                                ...webCrawlerElementDistanceCalculatorRequest,
                                                                urls: urls
                                                            })
                                                        } else {
                                                            setWebCrawlerElementDistanceCalculatorRequest({
                                                                ...webCrawlerElementDistanceCalculatorRequest,
                                                                urls: []
                                                            })
                                                        }
                                                    }}
                                                    value={webCrawlerElementDistanceCalculatorRequest.urls.join("\n")}
                                                    minRows={10}
                                                    maxRows={10}
                                                />
                                            </Box>
                                            <Box
                                                display='flex'
                                                justifyContent='start'
                                                mt='20px'
                                                gap='20px'
                                            >
                                                <ButtonExt
                                                    type='button'
                                                    onClickEvent={() => onCalculate(values)}
                                                    value={
                                                        elementDistanceCalculateMutation.isLoading
                                                            ? 'Calculating...'
                                                            : 'Calculate'
                                                    }
                                                    disabled={
                                                        elementDistanceCalculateMutation.isLoading ||
                                                        (webCrawlerElementDistanceCalculatorRequest.urls.length === 0)
                                                    }
                                                />
                                            </Box>
                                        </>
                                    }
                                />
                            </Box>
                        )}

                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(2, minmax(0,1fr))'
                        >
                            {values.type === 'WEB' && (
                                <>
                                    <CheckboxExt
                                        label="Recursive Crawl"
                                        onChangeEvent={handleChange}
                                        value={values.metadata.recursiveCrawl}
                                        name="metadata.recursiveCrawl"
                                        editable={user?.user?.role === 'ADMIN'}
                                    />
                                    {values.metadata.recursiveCrawl && (
                                        <>
                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Allowed Maximum Depth To Crawl"
                                                onChange={handleChange}
                                                value={values.metadata.allowedMaximumDepthToCrawl}
                                                name="metadata.allowedMaximumDepthToCrawl"
                                                error={!!touched.metadata?.allowedMaximumDepthToCrawl && !!errors.metadata?.allowedMaximumDepthToCrawl}
                                                helperText={touched.metadata?.allowedMaximumDepthToCrawl && errors.metadata?.allowedMaximumDepthToCrawl}
                                                disabled={user?.user?.role !== 'ADMIN'}
                                            />
                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Allowed Maximum Pages To Fetch"
                                                onChange={handleChange}
                                                value={values.metadata.allowedMaximumPagesToFetch}
                                                name="metadata.allowedMaximumPagesToFetch"
                                                error={!!touched.metadata?.allowedMaximumPagesToFetch && !!errors.metadata?.allowedMaximumPagesToFetch}
                                                helperText={touched.metadata?.allowedMaximumPagesToFetch && errors.metadata?.allowedMaximumPagesToFetch}
                                                disabled={user?.user?.role !== 'ADMIN'}
                                            />
                                        </>
                                    )}
                                    <AutocompleteExt
                                        name="metadata.crawlerTextRegexExtractorIds"
                                        multiSelection={true}
                                        label="Text Regex Extractors"
                                        selectedValue={values.metadata.crawlerTextRegexExtractorIds}
                                        options={crawlerTextRegexExtractorOptions}
                                        onSelect={(v) => {
                                            setCrawlerRequest({
                                                ...values,
                                                metadata: {
                                                    ...values.metadata,
                                                    crawlerTextRegexExtractorIds: v
                                                }
                                            })

                                            values.metadata = {
                                                ...values.metadata,
                                                crawlerTextRegexExtractorIds: v
                                            }
                                        }}
                                        error={!!touched.metadata?.crawlerTextRegexExtractorIds && !!errors.metadata?.crawlerTextRegexExtractorIds}
                                        helperText={touched.metadata?.crawlerTextRegexExtractorIds && errors.metadata?.crawlerTextRegexExtractorIds}
                                        editable={user?.user?.role === 'ADMIN'}
                                    />
                                    <SelectExt
                                        name="metadata.crawlerTextRegexExtractByFields"
                                        multiSelection={true}
                                        isClearable={true}
                                        label="Extract From"
                                        selectedValue={values.metadata.crawlerTextRegexExtractByFields}
                                        options={crawlerTextRegexExtractByFieldOptions}
                                        onSelect={(v) => {
                                            setCrawlerRequest({
                                                ...values,
                                                metadata: {
                                                    ...values.metadata,
                                                    crawlerTextRegexExtractByFields: v
                                                }
                                            })

                                            values.metadata = {
                                                ...values.metadata,
                                                crawlerTextRegexExtractByFields: v
                                            }
                                        }}
                                        error={!!touched.metadata?.crawlerTextRegexExtractByFields && !!errors.metadata?.crawlerTextRegexExtractByFields}
                                        helperText={touched.metadata?.crawlerTextRegexExtractByFields && errors.metadata?.crawlerTextRegexExtractByFields}
                                        editable={user?.user?.role === 'ADMIN' && values.metadata && values.metadata.crawlerTextRegexExtractorIds && values.metadata.crawlerTextRegexExtractorIds.length > 0}
                                    />
                                </>
                            )}

                            <CheckboxExt
                                label="Enabled"
                                onChangeEvent={handleChange}
                                value={values.enabled}
                                name="enabled"
                                editable={user?.user?.role === 'ADMIN'}
                            />

                            {!isNew && (
                                <>
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Process Start'
                                        value={crawler!!.processStart ? crawler!!.processStart : "-"}
                                        name='processStart'
                                        disabled={true}
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Process End'
                                        value={crawler!!.processEnd ? crawler!!.processEnd : '-'}
                                        name='processEnd'
                                        disabled={true}
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Crawler Status'
                                        value={crawler!!.status}
                                        name='status'
                                        disabled={true}
                                    />
                                    <TextField
                                        variant='filled'
                                        type='text'
                                        label='Public Status'
                                        value={crawler!!.dataStatus ? crawler!!.dataStatus : '-'}
                                        name='dataStatus'
                                        disabled={true}
                                    />
                                </>
                            )}

                            <Box display="grid" gridTemplateColumns="1fr 3em">
                                <CheckboxExt
                                    label="Enable Reprocess"
                                    onChange={(v) => {
                                        if (v) {
                                            setCrawlerRequest({
                                                ...crawlerRequest,
                                                filteredChunksToExcludeUndesirableTextBasedOnNlp: [],
                                                filteredChunksToExcludeUndesirablePatterns: [],
                                                acceptableMinChunkSizeInWords: undefined,
                                                enableReprocess: v
                                            })

                                            values.filteredChunksToExcludeUndesirableTextBasedOnNlp = []
                                            values.filteredChunksToExcludeUndesirablePatterns = []
                                            values.acceptableMinChunkSizeInWords = undefined
                                            values.enableReprocess = v
                                        } else {
                                            setCrawlerRequest({
                                                ...crawlerRequest,
                                                enableReprocess: v
                                            })

                                            values.enableReprocess = v
                                        }
                                    }}
                                    value={values.enableReprocess}
                                    name="enableReprocess"
                                    editable={user?.user?.role === 'ADMIN'}
                                />
                                <Tooltip
                                    title="Note: Changing enable reprocess to true will remove the post-processing filters."
                                    placement='top'>
                                    <InfoIcon />
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box
                            display='grid'
                            mt='20px'
                            gap='30px'
                            gridTemplateColumns='repeat(1, minmax(0,1fr))'
                        >
                            <TextareaAutosizeExt
                                label="Error"
                                value={crawler?.error}
                                name="error"
                                editable={false}
                                minRows={5}
                                maxRows={5}
                            />
                        </Box>

                        {!isNew && !values.enableReprocess && (
                            <Box
                                display='grid'
                                mt='20px'
                                gap='30px'
                                gridTemplateColumns='repeat(1, minmax(0,1fr))'
                            >
                                <AccordionExt
                                    title="Post Processing Filters"
                                    component={
                                        <>
                                            <Box mt='20px'
                                                 gap='30px'>
                                                <SelectExt
                                                    name="filteredChunksToExcludeUndesirableTextBasedOnNlp"
                                                    multiSelection={true}
                                                    isClearable={true}
                                                    label="Filtered Chunks To Exclude Undesirable Text Based On NLP"
                                                    selectedValue={values.filteredChunksToExcludeUndesirableTextBasedOnNlp}
                                                    options={filteredChunksToExcludeUndesirableTextBasedOnNlpOptions}
                                                    onSelect={(v) => {
                                                        setCrawlerRequest({
                                                            ...values,
                                                            filteredChunksToExcludeUndesirableTextBasedOnNlp: v
                                                        })

                                                        values.filteredChunksToExcludeUndesirableTextBasedOnNlp = v
                                                    }}
                                                    error={!!touched.filteredChunksToExcludeUndesirableTextBasedOnNlp && !!errors.filteredChunksToExcludeUndesirableTextBasedOnNlp}
                                                    helperText={touched.filteredChunksToExcludeUndesirableTextBasedOnNlp && errors.filteredChunksToExcludeUndesirableTextBasedOnNlp}
                                                    editable={user?.user?.role === 'ADMIN'}
                                                />

                                                <Box display='grid'
                                                     gridTemplateColumns='9fr 1fr'
                                                     mt='20px'
                                                     mb='20px'
                                                     gap='30px'>
                                                    <TagInputExt
                                                        name="filteredChunksToExcludeUndesirablePatterns"
                                                        label="Filtered Chunks To Exclude Undesirable Patterns"
                                                        values={values.filteredChunksToExcludeUndesirablePatterns.map(
                                                            (filteredChunksToExcludeUndesirablePattern) => {
                                                                return {
                                                                    id: filteredChunksToExcludeUndesirablePattern,
                                                                    text: filteredChunksToExcludeUndesirablePattern,
                                                                }
                                                            }
                                                        )}
                                                        onItemAdd={(v) => {
                                                            setCrawlerRequest({
                                                                ...values,
                                                                filteredChunksToExcludeUndesirablePatterns: values.filteredChunksToExcludeUndesirablePatterns.concat(v.id)
                                                            })

                                                            values.filteredChunksToExcludeUndesirablePatterns = values.filteredChunksToExcludeUndesirablePatterns.concat(v.id)
                                                        }}
                                                        onItemDelete={(index) => {
                                                            const cloneFilteredChunksToExcludeUndesirablePatterns = [
                                                                ...values.filteredChunksToExcludeUndesirablePatterns,
                                                            ]

                                                            cloneFilteredChunksToExcludeUndesirablePatterns.splice(
                                                                index,
                                                                1
                                                            )

                                                            setCrawlerRequest({
                                                                ...values,
                                                                filteredChunksToExcludeUndesirablePatterns: cloneFilteredChunksToExcludeUndesirablePatterns
                                                            })

                                                            values.filteredChunksToExcludeUndesirablePatterns = cloneFilteredChunksToExcludeUndesirablePatterns
                                                        }}
                                                        editable={user?.user?.role === 'ADMIN'}
                                                    />
                                                    {values.filteredChunksToExcludeUndesirablePatterns.length > 0 && (
                                                        <CopyToClipboard text={values.filteredChunksToExcludeUndesirablePatterns.join("|")}>
                                                            <IconButton color="secondary">
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </CopyToClipboard>
                                                    )}
                                                </Box>
                                                {values.filteredChunksToExcludeUndesirablePatterns.length > 0 && (
                                                    <Box textAlign="right">
                                                        <ButtonExt
                                                            type="button"
                                                            value="Clear"
                                                            onClickEvent={() => {
                                                                setCrawlerRequest({
                                                                    ...values,
                                                                    filteredChunksToExcludeUndesirablePatterns: []
                                                                })

                                                                values.filteredChunksToExcludeUndesirablePatterns = []
                                                            }}
                                                        />
                                                    </Box>
                                                )}

                                                <TextField
                                                    style={{ marginBottom: `20px` }}
                                                    variant="filled"
                                                    fullWidth={true}
                                                    type="number"
                                                    label="Acceptable Min Chunk Size In Words"
                                                    onChange={handleChange}
                                                    value={values.acceptableMinChunkSizeInWords}
                                                    name="acceptableMinChunkSizeInWords"
                                                    error={!!touched.acceptableMinChunkSizeInWords && !!errors.acceptableMinChunkSizeInWords}
                                                    helperText={touched.acceptableMinChunkSizeInWords && errors.acceptableMinChunkSizeInWords}
                                                    disabled={user?.user?.role !== 'ADMIN'}
                                                />

                                                <CheckboxExt
                                                    label="Filtered By Special Characters"
                                                    onChangeEvent={handleChange}
                                                    value={values.filteredBySpecialCharacters}
                                                    onChange={(v) => {
                                                        setCrawlerRequest({
                                                            ...values,
                                                            filteredBySpecialCharacters: v
                                                        })

                                                        values.filteredBySpecialCharacters = v
                                                    }}
                                                    name="filteredBySpecialCharacters"
                                                    editable={user?.user?.role === 'ADMIN'}
                                                />
                                            </Box>
                                        </>
                                    }
                                />
                            </Box>
                        )}

                        {user?.user?.role && user.user.role === 'ADMIN' && (!crawler?.dataStatus || crawler?.dataStatus === 'WITHDRAWN') && (
                            <Box
                                display='flex'
                                justifyContent='end'
                                mt='20px'
                                gap='20px'
                            >
                                <ButtonExt
                                    type='button'
                                    value={
                                        getCrawlerQuery.isLoading
                                            ? 'Refreshing'
                                            : 'Refresh'
                                    }
                                    onClickEvent={onRefresh}
                                    disabled={
                                        getCrawlerQuery.isLoading ||
                                        (crawler?.status !== 'PROCESSING' && crawler?.status !== 'UPDATING')
                                    }
                                />
                                <ButtonExt
                                    type='submit'
                                    value={
                                        crawlerCreateOrUpdateMutation.isLoading
                                            ? 'Saving'
                                            : 'Save'
                                    }
                                    disabled={
                                        crawlerCreateOrUpdateMutation.isLoading ||
                                        (crawler?.status === 'PROCESSING' || crawler?.status === 'UPDATING')
                                    }
                                />
                                <ButtonExt
                                    type="button"
                                    value={
                                        deleteCrawlerDataMutation.isLoading
                                            ? 'Deleting Data'
                                            : 'Delete Data'
                                    }
                                    onClickEvent={() => {
                                        setShowConfirmation({
                                            action: "DELETE_DATA",
                                            message: 'Are you sure you want to delete data?',
                                            enable: true
                                        })
                                    }}
                                    disabled={!hasCrawlerData}
                                />
                            </Box>
                        )}
                    </form>
                )}
            </Formik>

            {!isNew && (
                <Box mt='20px'>
                    <AccordionExt
                        titleElement={<Header title="Latest Crawled Data" titleVariant="h3" />}
                        component={<CrawlerDataDetail
                            crawlerId={crawler!!.id!!}
                            callback={(value) => {
                                setHasCrawlerData(value)
                                setRefreshCrawlerData(false)
                            }}
                            refreshCrawlerData={refreshCrawlerData} />}
                    />
                </Box>
            )}

            <Box
                display='flex'
                justifyContent='end'
                mt='20px'
                gap='20px'
            >
                {user?.user?.role && user.user.role === 'ADMIN' && crawler?.status === 'COMPLETE' && (!crawler?.dataStatus || crawler?.dataStatus === 'WITHDRAWN') && (
                    <>
                        <ButtonExt
                            type='button'
                            value={
                                deleteCrawlerMutation.isLoading
                                    ? 'Deleting'
                                    : 'Delete'
                            }
                            disabled={
                                deleteCrawlerMutation.isLoading
                            }
                            onClickEvent={() => {
                                setShowConfirmation({
                                    action: "DELETE_ALL",
                                    message: 'Are you sure you want to delete the entire crawler?',
                                    enable: true
                                })
                            }}
                        />

                        <ButtonExt
                            type='button'
                            value={
                                crawlerPublishMutation.isLoading
                                    ? 'Publishing'
                                    : 'Publish'
                            }
                            disabled={
                                crawlerPublishMutation.isLoading ||
                                !hasCrawlerData
                            }
                            onClickEvent={() => {
                                setShowConfirmation({
                                    action: "PUBLISH",
                                    message: 'Are you sure you want to publish?',
                                    enable: true
                                })
                            }}
                        />
                    </>
                )}

                {user?.user?.role && user.user.role === 'ADMIN' && crawler?.dataStatus === 'PUBLISHED' && (
                    <ButtonExt
                        type='button'
                        value={
                            crawlerWithdrawMutation.isLoading
                                ? 'Withdrawing'
                                : 'Withdraw'
                        }
                        disabled={
                            crawlerWithdrawMutation.isLoading ||
                            !crawlerPublicDatasetComplete
                        }
                        onClickEvent={() => {
                            setShowConfirmation({
                                action: "WITHDRAW",
                                message: 'Are you sure you want to withdraw?',
                                enable: true
                            })
                        }}
                    />
                )}

                {user?.user?.role && user.user.role === 'ADMIN' && crawler?.dataStatus === 'PUBLISHING' && (
                    <>
                        {!crawlerPublicDatasetComplete && (
                            <ButtonExt
                                type='button'
                                value={getCrawlerPublicDatasetStatusQuery.isLoading ? 'Checking...' : 'Checking'}
                                onClickEvent={() => {
                                    getCrawlerPublicDatasetStatusQuery.refetch()
                                }}
                            />
                        )}
                    </>
                )}

                <ConfirmationDialog open={showConfirmation.enable} message={showConfirmation.message} onConfirm={(confirmed) => {
                    if (confirmed) {
                        switch (showConfirmation.action) {
                            case "DELETE_ALL":
                                onDelete()
                                break
                            case "DELETE_DATA":
                                onDeleteData()
                                setRefreshCrawlerData(true)
                                break
                            case "PUBLISH":
                                onPublish()
                                break
                            case "WITHDRAW":
                                onWithdraw()
                                break
                        }
                    } else {
                        setShowConfirmation({
                            action: "",
                            message: "",
                            enable: false
                        })
                    }
                }} />

            </Box>
        </Box>
    )
}


/**
 * Connect and retrieve the current user through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user }
}

export default connect(mapStateToProps)(CrawlerConfigurationDetail)