import React, { useEffect, useMemo, useState } from 'react'
import { mediaQuery } from './MediaQuery'
import { v4 as uuidv4 } from 'uuid'
import store from '../redux/store'
import { LOAD_TABLE_DATA } from '../redux/actionType'
import { connect } from 'react-redux'
import { useAuthQueryWithQueryFunction } from '../extensions/UseAuthQuery'
import styled from 'styled-components'
import {Box, Paper, TextField, Typography, useTheme} from '@mui/material'
import DataGrid, {
    DataGridColumnDescription,
    TablePaginationChangeProp,
} from './DataGrid'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download';
import RestoreIcon from '@mui/icons-material/Restore'
import { useNavigate } from 'react-router-dom'
import { ApiError } from '../interfaces/ErrorType'
import ErrorMessage from './ErrorMessage'
import ButtonExt from './ButtonExt'
import CheckboxExt from "./Checkbox";
import {Formik} from "formik";
import {useMutation} from "react-query";
import iconv from 'iconv-lite';
import {tokens} from "../theme";

// Create a reusable styled div component
const StyledSearchCriteriaDiv = styled.div<{ searchFilterCols: number }>`
    display: grid;
    grid-template-columns: ${({ searchFilterCols }) =>
    [...Array(searchFilterCols)].map(() => '1fr ')};
    grid-template-rows: auto;
    column-gap: 1em;
    row-gap: 1em;
    margin: 1rem;
    text-align: left;

    ${mediaQuery('tablet')`
      grid-template-columns: auto;
  `};
`

const DataGridFilter = (props: DataGridFilterProp) => {
    const {
        keyField,
        multiSelect = false,
        useQueryKey,
        reduxTableData,
        columns,
        onSearchPageUseQueryEvent,
        reportTitle,
        onDownloadEvent,
        searchFilterCols = 2,
        disabledMatchAll = false,
        disabledKeyword = false,
        customSearchOptions,
        resetCustomSearchOptions,
        change,
        customSearchOptionsRenderer,
        customActionInputRenderer,
        createPageUrl,
        hasCreatePermission = false,
        expandRow,
        selectedRow,
        maxItemsPerPage,
        hasPagination = true,
        preloadEnabled = true,
    } = props

    const navigate = useNavigate()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    // Filter specific table data based on useQueryKey
    const tableData = reduxTableData.find(
        (each: any) => each?.key === useQueryKey
    )?.data
    const sizePerPageList = maxItemsPerPage ? [10, 25, 50, 100, maxItemsPerPage] : [10, 25, 50, 100]
    const [keyword, setKeyword] = useState<string>('')
    const [reset, setReset] = useState<boolean>(false)
    const [download, setDownload] = useState<boolean>(false)
    const [matchAllKeys, setMatchAllKeys] = useState<boolean>(false)
    const defaultCustomSearchOptions = useMemo(() => customSearchOptions, [])
    const defaultSearchOptions = hasPagination ? {
        uuid: undefined,
        keyword: '',
        matchAllKeys: false,
        customSearchOptions: { ...defaultCustomSearchOptions },
        pageOptions: {
            page: 1,
            pageLimit: sizePerPageList[0],
        },
        sortOptions: undefined,
    } : {
        uuid: undefined,
        keyword: '',
        matchAllKeys: false,
        customSearchOptions: { ...defaultCustomSearchOptions },
    }
    const [preLoaded, setPreLoaded] = useState<boolean>(false)

    const [searchOptions, setSearchOptions] = useState<SearchOptionsProp>({
        ...defaultSearchOptions,
    })

    const { data, error, status, refetch } = useAuthQueryWithQueryFunction<
        any,
        ApiError,
        any
        >(
        [useQueryKey, searchOptions],
        () => onSearchPageUseQueryEvent(searchOptions),
        {
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: false, // disable this query from automatically running
            onSuccess(data) {
                store.dispatch({
                    type: LOAD_TABLE_DATA,
                    payload: { key: useQueryKey, data: data?.data?.content ? data?.data?.content : (data?.content ? data?.content : data?.data) },
                })
                if (reset) {
                    setReset(false)
                }
            },
            onError(error) {
                if (reset) {
                    setReset(false)
                }
            },
        }
    )

    const downloadMutation = useMutation<Blob, ApiError, SearchOptionsProp>(onDownloadEvent!);

    useEffect(() => {
        if (preloadEnabled || preLoaded) {
            refetch()
        } else {
            setPreLoaded(true)
        }
    }, [searchOptions])

    useEffect(() => {
        if (preloadEnabled || preLoaded) {
            onSearch()
        }
    }, [change])

    const totalSize: number = data?.data?.totalElements
        ? data.data.totalElements
        : (data?.data instanceof Array ? data?.data?.length : (data?.totalElements ? data?.totalElements : 0))

    const handleTableChange = async (
        type: string,
        valueChange: TablePaginationChangeProp
    ) => {
        if (type === 'sort') {
            if (hasPagination) {
                setSearchOptions({
                    ...searchOptions,
                    pageOptions: {
                        ...searchOptions.pageOptions!!,
                        page: 1,
                    },
                    sortOptions: {
                        ...searchOptions.sortOptions,
                        sortField: valueChange.sortField,
                        sortOrder: valueChange.sortOrder,
                    },
                })
            } else {
                setSearchOptions({
                    ...searchOptions,
                    sortOptions: {
                        ...searchOptions.sortOptions,
                        sortField: valueChange.sortField,
                        sortOrder: valueChange.sortOrder,
                    },
                })
            }
        }

        if (type === 'pagination') {
            setSearchOptions({
                ...searchOptions,
                uuid: uuidv4(),
                pageOptions: {
                    ...searchOptions.pageOptions,
                    page: valueChange.page === 0 ? 1 : valueChange.page,
                    pageLimit: valueChange.sizePerPage,
                },
            })
        }
    }

    const onSearch = async () => {
        if (hasPagination) {
            setSearchOptions({
                ...searchOptions,
                uuid: uuidv4(),
                pageOptions: {
                    ...searchOptions.pageOptions!!,
                    page: 1,
                },
                keyword: keyword,
                customSearchOptions: customSearchOptions,
            })
        } else {
            setSearchOptions({
                ...searchOptions,
                uuid: uuidv4(),
                keyword: keyword,
                customSearchOptions: customSearchOptions,
            })
        }
    }

    const onDownload = async () => {
        if (!onDownloadEvent) {
            return;
        }

        try {
            setDownload(true);

            const downloadRequest = hasPagination ? {
                ...searchOptions,
                pageOptions: {
                    ...searchOptions.pageOptions!!,
                    page: 1,
                },
                keyword: keyword,
                customSearchOptions: customSearchOptions,
            } : {
                ...searchOptions,
                keyword: keyword,
                customSearchOptions: customSearchOptions,
            }

            const data = await downloadMutation.mutateAsync(downloadRequest);
            const rawDataAsString = String(data)

            // TODO: currently apply to windows-1252 only
            // Detect the actual encoding of the data
            const detectedEncoding = "windows-1252"
            // const encodingResult = jschardet.detect(rawDataAsString);
            // const detectedEncoding = encodingResult.encoding;

            // Convert the data based on detected encoding type
            const encodedData = iconv.encode(rawDataAsString, detectedEncoding);

            // Create a Blob from the encoded CSV data
            const file = new Blob([encodedData], { type: 'application/octet-stream; charset=' + detectedEncoding });

            // Create a URL for the Blob
            const fileURL = URL.createObjectURL(file);

            // Create a link element
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `${reportTitle}-${new Date().toISOString().replace(/[-:.TZ]/g, '')}.csv`; // Set the desired file name and extension

            // Append the link to the document body and click it
            document.body.appendChild(link);
            link.click();

            // Clean up the created URL and remove the link element
            URL.revokeObjectURL(fileURL);
            document.body.removeChild(link);
        } finally {
            setDownload(false);
        }
    };

    const onReset = async () => {
        setReset(true)
        setKeyword('')
        setMatchAllKeys(false)

        if (!preloadEnabled) {
            setPreLoaded(false)
        }

        setSearchOptions({
            ...defaultSearchOptions,
            uuid: uuidv4(),
        })

        if (resetCustomSearchOptions) {
            resetCustomSearchOptions({
                ...defaultSearchOptions.customSearchOptions,
                uuid: uuidv4(),
            })
        }

        store.dispatch({
            type: LOAD_TABLE_DATA,
            payload: { key: useQueryKey, data: [] },
        })

        if (!preloadEnabled) {
            setReset(false)
        }
    }

    return (
        <>
            <Box style={{ marginBottom: `2em` }}>
                {status === 'error' && <ErrorMessage error={error} />}
            </Box>

            <Formik
                onSubmit={onSearch}
                initialValues={searchOptions}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <StyledSearchCriteriaDiv searchFilterCols={searchFilterCols}>
                            {!disabledKeyword && (
                                <div
                                    style={{
                                        display: `grid`,
                                        gridTemplateColumns: !disabledMatchAll
                                            ? `3fr 2fr`
                                            : `1fr`,
                                        gridTemplateRows: `auto`,
                                    }}
                                >
                                    <TextField
                                        style={{ width: `auto`, height: `auto` }}
                                        name="keyword"
                                        value={keyword}
                                        label="Search keyword..."
                                        onChange={(e) => setKeyword(e.target.value)}
                                    />

                                    {!disabledMatchAll && (
                                        <CheckboxExt
                                            name="matchAllKeys"
                                            value={matchAllKeys}
                                            onChange={(v) => setMatchAllKeys(v)}
                                            label="Match All Keys:"
                                        />
                                    )}
                                </div>
                            )}

                            {customSearchOptionsRenderer}
                        </StyledSearchCriteriaDiv>

                        <StyledSearchCriteriaDiv searchFilterCols={1}>
                            <div style={{ textAlign: `right` }}>
                                {createPageUrl && (
                                    <ButtonExt
                                        style={{
                                            width: `auto`,
                                            height: `auto`,
                                            margin: `5px`,
                                        }}
                                        icon={<AddIcon />}
                                        value="Add"
                                        onClickEvent={() => navigate(createPageUrl)}
                                        disabled={!hasCreatePermission}
                                    />
                                )}
                                <ButtonExt
                                    type="submit"
                                    style={{
                                        width: `auto`,
                                        height: `auto`,
                                        margin: `5px`,
                                    }}
                                    icon={<SearchIcon />}
                                    value={
                                        status === 'loading' && !reset
                                            ? 'Searching...'
                                            : 'Search'
                                    }
                                />
                                {(reportTitle && onDownloadEvent) && (
                                    <ButtonExt
                                        style={{
                                            width: `auto`,
                                            height: `auto`,
                                            margin: `5px`,
                                        }}
                                        icon={<DownloadIcon />}
                                        value={
                                            download
                                                ? 'Downloading...'
                                                : 'Download'
                                        }
                                        onClickEvent={() => onDownload()}
                                    />
                                )}
                                <ButtonExt
                                    style={{
                                        width: `auto`,
                                        height: `auto`,
                                        margin: `5px`,
                                    }}
                                    icon={<RestoreIcon />}
                                    value={reset ? 'Resetting...' : 'Reset'}
                                    onClickEvent={() => onReset()}
                                />
                            </div>
                        </StyledSearchCriteriaDiv>

                        {customActionInputRenderer && (
                            <Box margin="20px" gap="20px">
                                {customActionInputRenderer}
                            </Box>
                        )}
                    </form>
                )}
            </Formik>

            {hasPagination ? (
                <DataGrid
                    keyField={keyField}
                    multiSelect={multiSelect}
                    columns={columns}
                    data={tableData ? tableData : []}
                    paginationFactory={{
                        page: searchOptions.pageOptions!!.page,
                        sizePerPage: searchOptions.pageOptions!!.pageLimit,
                        totalSize: totalSize,
                    }}
                    expandRow={expandRow}
                    selectedRow={selectedRow}
                    rowPerPageOptions={sizePerPageList}
                    onTableChange={(type, valueChange) =>
                        handleTableChange(type, valueChange)
                    }
                />
            ) : (
                <>
                    <DataGrid
                        keyField={keyField}
                        multiSelect={multiSelect}
                        columns={columns}
                        data={tableData ? tableData : []}
                        expandRow={expandRow}
                        selectedRow={selectedRow}
                        rowPerPageOptions={sizePerPageList}
                        onTableChange={(type, valueChange) =>
                            handleTableChange(type, valueChange)
                        }
                    />
                    <Paper
                        elevation={3}
                        style={{
                            marginLeft: '1.5em',
                            marginRight: '1.5em',
                            padding: '1em',
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? colors.blueAccent[700]
                                    : colors.grey[800],
                        }}
                    >
                        <Typography variant="h6">
                            Total Items: {totalSize}
                        </Typography>
                    </Paper>
                </>
            )}
        </>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface DataGridFilterProp {
    keyField: string
    multiSelect?: boolean
    useQueryKey: string
    reduxTableData: any
    columns: DataGridColumnDescription[]
    onSearchPageUseQueryEvent: (searchOptions: SearchOptionsProp) => void // Handle event upon search page change
    reportTitle?: string
    onDownloadEvent?: (searchOptions: SearchOptionsProp) => Promise<any> // Handle event upon download change
    searchFilterCols?: number
    disabledMatchAll?: boolean
    disabledKeyword?: boolean
    customSearchOptions?: any
    resetCustomSearchOptions?: (setCustomSearchOptions?: any) => void // Reset
    change?: string | undefined
    customSearchOptionsRenderer?: JSX.Element
    customActionInputRenderer?: JSX.Element
    createPageUrl?: string
    hasCreatePermission?: boolean | undefined
    expandRow: (row: any) => any
    selectedRow?: (row: any[]) => void
    maxItemsPerPage?: number
    hasPagination?: boolean
    preloadEnabled?: boolean
}

export interface SearchOptionsProp {
    uuid: string | undefined
    keyword: string | undefined
    customSearchOptions: any
    pageOptions?: PageOptionsProp | undefined
    sortOptions?: SortOptionsProp | undefined
}

interface PageOptionsProp {
    page: number
    pageLimit: number
}

interface SortOptionsProp {
    sortField?: string | undefined
    sortOrder?: string | undefined
}

/**
 * Connect and retrieve the current table data through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { reduxTableData: state.tableData.data }
}

export default connect(mapStateToProps)(DataGridFilter)
