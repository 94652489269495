import SecureAxios from "../extensions/SecureAxios";

/**
 * An action to fetch admin crawler text regex extractor
 * @param searchOptions
 * @returns {Promise<*>}
 */
export const fetchAdminCrawlerTextRegexExtractors = async (searchOptions) => {
    let adminCrawlerTextExtractorsSearchUrl = `/crawler/text/regex/extractors?keyword=${searchOptions.keyword}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        adminCrawlerTextExtractorsSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(adminCrawlerTextExtractorsSearchUrl)
    return data
}

/**
 * An action to fetch admin crawler text regex extractor by nubmer value type
 * @returns {Promise<*>}
 */
export const findCrawlerTextRegexExtractorsByNumberValueType = async () => {
    const { data } = await SecureAxios.get(`/crawler/text/regex/extractors?valueTypes=NUMBER,NUMBER_RANGE&page=1&size=10000&sort=priority&sortDir=DESC`)
    return data.data.content
}

/**
 * An action to save crawler text regex extractor
 */
export async function saveCrawlerTextRegexExtractor(crawlerTextRegexExtractor) {
    const requestBody = {
        ...crawlerTextRegexExtractor,
    }
    let crawlerTextRegexExtractorUrl = `/admin/crawler/text/regex/extractor`
    if (crawlerTextRegexExtractor.id) {
        crawlerTextRegexExtractorUrl += `/${crawlerTextRegexExtractor.id}/update`

        const { data } = await SecureAxios.put(crawlerTextRegexExtractorUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return data.data
    } else {
        const { data } = await SecureAxios.post(crawlerTextRegexExtractorUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        return data.data
    }
}

